import React, {useState} from 'react';
import PremiumItem from "./PremiumItem";
import classes from "./style.module.css";

const PremiumList = () => {
    const [premiumList, setPremiumList] = useState([
        {
            title: '7 Day Trial',
            spanMotivated: 'Experience, educate yourself, uncover exciting features.',
            price: 'Free for 7 Days',
            allowBCount: 1,
            allowedUM: 100,
            mbAllowed: '10mb',
            plan: 'Free',
            prTag: 0,
        },
        {
            title: 'Starter Plan',
            spanMotivated: 'Perfect for running small projects.',
            price: 'US 5$/ Month',
            allowBCount: 2,
            allowedUM: 1000,
            mbAllowed: '100mb',
            prTag: 5,
            plan: 'Starter',
        },
        {
            title: 'Basic Plan',
            spanMotivated: 'Good for any kind of project you imagine.',
            price: 'US 10$/ Month',
            allowBCount: 4,
            allowedUM: 10000,
            mbAllowed: '200mb',
            prTag: 10,
            plan: 'Basic',
        },
        {
            title: 'Expanded Plan',
            spanMotivated: 'Designed for real money-makers.',
            price: 'US 13$/ Month',
            allowBCount: 8,
            allowedUM: 20000,
            mbAllowed: '400mb',
            prTag: 13,
            plan: 'Expanded',
        },
    ])
    return (
        <div className={classes.premiumCont}>
            {premiumList.map((item, index) =>
                <PremiumItem obj={item}></PremiumItem>
            )}
        </div>
    );
};

export default PremiumList;