import React, {useEffect} from 'react';
import LoadingDots from "../../components/General/Loading/LoadingDots";
import {useFetching} from "../../hooks/useFetching";
import {getCookie} from "../../helpers/helper";
import {useNavigate} from "react-router-dom";
import paymentApi from "../../api/paymentApi";
import {useDispatch} from "react-redux";

const PremiumPlanLoader = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const [getApprovePayment, isGetApprovePaymentLoading, getApprovePaymentError] = useFetching(async () => {
        const transUid = getCookie('paymentUid')
        const response = await paymentApi.GetApprovePayment({"uid": transUid})
        navigate(`/plan-status/${transUid}`)
    })
    useEffect(() => {
        getApprovePayment()
    }, [])
    return (
        <div>
            <LoadingDots></LoadingDots>
        </div>
    );
};

export default PremiumPlanLoader;