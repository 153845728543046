import React from 'react';
import classes from "./docs.module.css";

const RightSidebarDoc = () => {
    return (
        <div className={classes.rightSidebarContainer}>

        </div>
    );
};

export default RightSidebarDoc;