import React, {useEffect, useState} from 'react';
import LandingHeader from "../../components/General/Header/LandingHeader";
import classes from "./styles.module.css";
import SnackBar from "../../components/General/Snackbar/SnackBar";
import {useDispatch, useSelector} from "react-redux";
import {removeToken, setIsAuth, setPassword, setToken, setUser} from "../../reducers/authSlice";
import SignUpLeftCard from "../../components/Auth/Oauth/SignUp/SignUpLeftCard";
import {apiPath} from "../../constants";
import {useSpring, animated} from 'react-spring'
import {useNavigate} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import authApi from "../../api/authApi";
import axios from "axios";
import {deleteCookie, setCookie} from "../../helpers/helper";
import {setMessage, setMessageType} from "../../reducers/generalSlice";
import {gapi} from "gapi-script";

const SignUpPage = () => {
    const dispatch = useDispatch()

    const card = useSpring({
        from: { x: window.innerWidth - 300},
        to: { x: 0 },
        config: {
            duration: 250,
        }
    })
    useEffect(() => {
        dispatch(setUser({}))
    }, [])
    return (
        <div style={{
            display:'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh'
        }}>
            <LandingHeader></LandingHeader>
            <div></div>
            <div style={{
                display: 'flex',
                width: '85%',
                minHeight: '80%',
                borderRadius: 32,
                padding: 15,
                backgroundColor: '#f3e1e129',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                    gap: 25,
                }}>
                    <SignUpLeftCard></SignUpLeftCard>
                    <animated.div style={{
                        width: '75%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...card,
                    }}>
                        <img src={apiPath+"/static/images/devises.png"} alt="Buildo Logo" style={{
                            width: '80%'
                        }}/>
                    </animated.div>
                </div>
            </div>
            <SnackBar></SnackBar>
        </div>
    );
};

export default SignUpPage;