import React, {useEffect, useState} from 'react';
import classes from "./styles.module.css";
import LandingHeader from "../../components/General/Header/LandingHeader";
import SnackBar from "../../components/General/Snackbar/SnackBar";
import PasswordRecover from "../../components/Auth/Oauth/PasswordRecover";
import {useFetching} from "../../hooks/useFetching";
import authApi from "../../api/authApi";
import {useDispatch} from "react-redux";
import {setMessage, setMessageType} from "../../reducers/generalSlice";
import VerifyPasswordRecover from "../../components/Auth/Oauth/VerifyPasswordRecover";

const PasswordRecoverPage = () => {
    const [uEmail, setUEmail] = useState('')
    const dispatch = useDispatch()
    const [sendRecoverPassword, isSendRecoverPasswordLoading, sendRecoverPasswordError] = useFetching(async () => {
        const response = await authApi.sendRecoverPassword({
            "email": uEmail
        })
        if(response.status === 200){
            dispatch(setMessageType(response.data.status))
            dispatch(setMessage(response.data.message))
        }else if(response.status === 404){
            dispatch(setMessageType(response.data.status))
            dispatch(setMessage(response.data.message))
            setUEmail('')
        }
    })
    useEffect(() => {
        sendRecoverPassword()
    }, [uEmail])
    return (
        <div style={{
            display:'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh'
        }}>
            <LandingHeader></LandingHeader>
            <div className={classes.login_container}>
                {uEmail===''
                    ?<PasswordRecover setUEmail={setUEmail}></PasswordRecover>
                    :<VerifyPasswordRecover uEmail={uEmail}></VerifyPasswordRecover>
                }
            </div>
            <SnackBar></SnackBar>
        </div>
    );
};

export default PasswordRecoverPage;