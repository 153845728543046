import React, {useEffect, useState} from 'react';
import Private from "../../navigations/Private";
import Public from "../../navigations/Public";
import {useDispatch, useSelector} from "react-redux";
import {useFetching} from "../../hooks/useFetching";
import authApi from "../../api/authApi";
import {removeToken, setIsAuth, setToken, setUser} from "../../reducers/authSlice";
import {deleteCookie, getCookie} from "../../helpers/helper";
import axios from "axios";

const PreventLogin = () => {
    const dispatch = useDispatch()
    const isAuth = useSelector((state) => state.auth.isAuth)
    const user = useSelector((state) => state.auth.user)
    const token = useSelector((state) => state.auth.token)

    const [getUser, isGetUserLoading, getUserError] = useFetching(async () => {
        const response = await authApi.getUser()
        if (response.status === 200){
            dispatch(setUser(response.data))
            dispatch(setIsAuth(true))
        }else{
            dispatch(removeToken())
            delete axios.defaults.headers.common["Authorization"];
        }
    })
    const [getRefreshToken, isGetRefreshTokenLoading, getRefreshTokenError] = useFetching(async () => {
        const response = await authApi.refreshToken({
            'refresh_token': token,
        })
        if (response.status === 200){
            const newAccessToken = response.data.access_token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        }
    })
    useEffect(() =>{
        dispatch(setToken(getCookie('token')))
        if(token != null) {
            getRefreshToken()
            getUser()
        }
    }, [token])
    return (token != null && !isGetRefreshTokenLoading ? <Private /> : <Public />);
};

export default PreventLogin;