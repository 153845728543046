import React, {useState} from 'react';
import './Cards.css'

const BotInfoListCard = () => {
    const [cards, setCards] = useState([
        {
            title: 'Build Powerful Bots',
            description: 'Unlock the potential of intelligent chatbots and automate conversations effortlessly with our user-friendly bot builder. Our platform empowers you to create highly customized and dynamic chatbots without any coding knowledge.'
        },
        {
            title: 'Customize and Personalize',
            description: 'Tailor your bots to perfectly align with your unique needs and brand identity using our advanced bot builder. Our platform provides you with granular control over every aspect of your bots\' conversational flow, design, and functionality. '
        },
        {
            title: 'Boost Engagement and Efficiency',
            description: 'Supercharge your customer support, boost engagement, and drive operational efficiency with our powerful bot builder. Our platform enables you to streamline your customer support processes by automating repetitive tasks, providing instant responses, and resolving common queries instantly.'
        },
        {
            title: 'Unleash Your Creativity',
            description: 'Unleash your creativity and transform your ideas into interactive and intelligent conversations using our cutting-edge bot builder. Our platform provides you with a comprehensive set of tools and features to design chatbots that go beyond simple Q&A. '
        },
        {
            title: 'Analytics and Insights',
            description: 'Gain valuable insights into your chatbot performance, measure user engagement, and optimize your bot strategy with our comprehensive analytics and insights dashboard.'
        },
    ])
    const [card, setCard] = useState(cards[Math.floor(Math.random() * cards.length)])
    return (
        <div className='mainCard'>
            <p style={{fontWeight:'bold', fontSize: 35}}>{card.title}</p>
            <p>{card.description}</p>
        </div>
    );
};

export default BotInfoListCard;