import React, {useState} from 'react';
import {Handle, Position} from "reactflow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faGear, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {getHumanByte} from "../../../../helpers/helper";
import {useFetching} from "../../../../hooks/useFetching";
import botStaticApi from "../../../../api/botStaticApi";
import {useSelector} from "react-redux";

const PostImportNode = (node) => {
    const selectedBot = useSelector((state) => state.profile.selectedBot)
    const [postFile, setPostFile] = useState(String(node.data.postFile))
    const [fileId, setFileId] = useState(null)

    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }
    const [savePostFile, isSavePostFileLoading, savePostFileError] = useFetching(async () => {
        const data = new FormData();
        data.append('bot', selectedBot.id);
        data.append('file', node.data.postFile);
        data.append('size', node.data.size_not);
        const response = await botStaticApi.UploadBotPostFile(data)
        setFileId(response.data.id)
        node.data.postFile = response.data.file
    })
    const [deletePostFile, isDeletePostFileLoading, deletePostFileError] = useFetching(async () => {
        const response = await botStaticApi.DeleteBotPostFile(fileId)
        setPostFile('')
    })
    return (
        <div className='node posts_import_node'>
            <Handle type="target" id="post_node" position={Position.Top} isValidConnection={(connection) => {return connection.targetHandle !== 'apiNode'}}/>
            <Handle type="source" className="buttons_node" id="buttons" position={Position.Bottom} isValidConnection={(connection) => {
                return connection.targetHandle === 'buttons'
            }}/>
            <div><span style={{fontSize:"15px",fontWeight:"bold"}}>Import Posts</span></div>
            <span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
            </span>
            <div className='post_settings'>
                {postFile===''
                    ?<div>
                        <label htmlFor={node.id+'file_import'}>
                            <div className="img_import">
                                <p>Please, add file</p>
                            </div>
                        </label>
                        <input type="file" id={node.id+'file_import'} onChange={(e) => {
                            setPostFile(e.target.files[0]);
                            node.data.postFile = e.target.files[0]
                            node.data.filename = e.target.files[0].name
                            node.data.size = getHumanByte(e.target.files[0].size)
                            node.data.size_not = e.target.files[0].size
                            savePostFile();
                        }} accept=".json" hidden/>
                    </div>
                    :<div>
                        <div className="file_preview" style={{marginTop: '10px'}}>
                            <FontAwesomeIcon icon={faFile} />
                            <div style={{flexGrow: 4}}>
                                <p className="filename">{node.data.filename}</p>
                                <p className="size">{node.data.size}</p>
                            </div>
                            <span title='Delete Post Image' className='delete_img' onClick={deletePostFile}><FontAwesomeIcon icon={faTrash} /></span>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default PostImportNode;