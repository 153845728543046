import React, {useEffect} from 'react';
import styles from './sidebar.module.css'
import {apiPath} from "../../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBasketShopping,
    faCartShopping,
    faCircleQuestion,
    faCode,
    faGear, faGem,
    faImage,
    faListUl,
    faPenToSquare,
    faShop,
    faStore,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedBot} from "../../../reducers/profileSlice";
import {useSpring, animated} from "react-spring";
import {setShowSidebar} from "../../../reducers/generalSlice";

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const bots = useSelector(state => state.profile.bots)
    const showSidebar = useSelector(state => state.general.showSidebar)
    const selectedBot = useSelector(state => state.profile.selectedBot)
    const [props, set, stop] = useSpring(() => ({left: 0, display:'flex'}))

    useEffect(() => {
        if(showSidebar === true){
            set({left:0, display:'block'})
        }else{
            set({left:-50, display:'none'})
        }
    }, [showSidebar])
    useEffect(() => {
        if(window.innerWidth < 576){
            dispatch(setShowSidebar(false))
        }
    }, [])
    return (
        <animated.div style={props}>
            <div className={styles.container}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    height: '100vh',
                    alignItems: 'center',
                    marginTop: '7px'
                }}>
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <div className={styles.child+ ' ' +styles.logo}>
                            B
                        </div>
                    </Link>
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <div className={location.pathname === "/" ? styles.child+ ' ' +styles.active : styles.child}>
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                    </Link>
                    {bots[0] !== undefined
                        ?[<Link to={`/${bots[0].username}/bot-details`} style={{ textDecoration: 'none' }} onClick={() => dispatch(setSelectedBot(bots[0]))}>
                            <div className={location.pathname === "/bot-details" ? styles.child+ ' ' +styles.active : styles.child}>
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </div>
                        </Link>,
                            <Link to={`/${bots[0].username}/set-up`} style={{ textDecoration: 'none' }}>
                                <div className={location.pathname === "/set-up" ? styles.child+ ' ' +styles.active : styles.child}>
                                    <FontAwesomeIcon icon={faCode} />
                                </div>
                            </Link>]
                    :<></>}
                    <Link to={'/plan'} style={{ textDecoration: 'none' }}>
                        <div className={location.pathname === "/plan" ? styles.child+ ' ' +styles.active : styles.child}>
                            <FontAwesomeIcon icon={faGem} />
                        </div>
                    </Link>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    marginBottom: "15px",
                }}>
                    <Link to={'/docs'}>
                        <div className={styles.child}>
                            <FontAwesomeIcon icon={faCircleQuestion} />
                        </div>
                    </Link>
                    <Link to={'/settings'}>
                        <div className={styles.child}>
                            <FontAwesomeIcon icon={faGear} />
                        </div>
                    </Link>
                </div>
            </div>
        </animated.div>
    );
};

export default Sidebar;
