import React, {useEffect, useState} from 'react';
import {useSpring, animated} from 'react-spring'
import classes from './styles.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {removeToken, setIsAuth, setPassword, setToken, setUser} from "../../../../reducers/authSlice";
import {useFetching} from "../../../../hooks/useFetching";
import authApi from "../../../../api/authApi";
import axios from "axios";
import {deleteCookie, setCookie} from "../../../../helpers/helper";
import {setMessage, setMessageType} from "../../../../reducers/generalSlice";
import {gapi} from "gapi-script";
import {GoogleLogin} from "react-google-login";

const SignUpLeftCard = () => {
    const clientId = '887897594904-5e832vubms881j2oofll7icjun0v54af.apps.googleusercontent.com';
    const user = useSelector(state => state.auth.user)
    const [email, setEmail] = useState('')
    const [password, setLocalPassword] = useState('')
    const [username, setUsername] = useState('')
    const [full_name, setFullName] = useState('')
    const [isGoogleAuth, setIsGoogleAuth] = useState(false)
    const [profilePic, setProfilePic] = useState('')
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(setPassword(password))
    }, [password])

    const [loginUser, isLoginUserLoading, loginUserError] = useFetching(async () => {
        console.log(email, password)
        if (email !== '' && password !== '' && /.+@.+\.[A-Za-z]+$/.test(email)){
            const data = new FormData();
            data.append('email', email);
            data.append('password', password);
            data.append('profile_pic', profilePic);
            data.append('is_google_auth', isGoogleAuth);
            data.append('username', username);
            data.append('full_name', full_name);

            const [res_data, res_status] = await authApi.loginUser(data)
            if(res_status === 200){
                axios.defaults.headers.common['Authorization'] = `Bearer ${res_data.access}`;
                setCookie('token', res_data.refresh)
                dispatch(setToken(res_data.refresh))
                dispatch(setIsAuth(true))
                dispatch(setUser(res_data.user))
                navigate('/');
            }
            else if(res_status === 207){
                dispatch(setUser(res_data.user))
            }
            else{
                dispatch(setMessage('Email or password is not correct.'))
                dispatch(setMessageType('error'))
                dispatch(removeToken())
                deleteCookie('token')
                delete axios.defaults.headers.common["Authorization"];
            }
        } else {
            dispatch(setMessage('Value Error||Email or Password is wrong please check if everything is correct'))
            dispatch(setMessageType('error'))
        }
    })

    useEffect(() => {
        setEmail('')
        setPassword('')
        setProfilePic('')
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);
    const onSuccess = (res) => {
        setEmail(res.profileObj.email)
        setPassword(res.profileObj.googleId)
        setProfilePic(res.profileObj.imageUrl)
        console.log(res.profileObj)
        setIsGoogleAuth(true);
        setUsername(res.profileObj.email.split("@")[0])
        if(res.profileObj.familyName) {
            setFullName(res.profileObj.givenName + ' ' +res.profileObj.familyName)
        }else{
            setFullName(res.profileObj.givenName )
        }
        loginUser()
    };
    const onFailure = (err) => {
        console.log('failed:', err);
    };
    const card = useSpring({
        from: { x: -200 },
        to: { x: 0 },
        config: {
            duration: 150,
        }
    })
    const text = useSpring({
        from: { y: 200 },
        to: { y: 0 },
        delay: 150,
        config: {
            duration: 150,
        }
    })
    const form = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 250,
        config: {
            duration: 200
        }
    })
    return (
        <animated.div style={{
            backgroundColor: 'white',
            borderRadius: 17,
            width: '25%',
            ...card,
        }}>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%',
                alignItems: 'center',
                gap: 50,
            }}>
                <animated.div style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    ...text,
                }}>
                    <p>Create Your</p>
                    <p>Buildo Account</p>
                </animated.div>
                <animated.div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15,
                    alignItems: 'center',
                    width: '100%',
                    ...form,
                }}>
                    <div className={classes.inputForm}>
                        <label htmlFor="fullNameForm">Your Full Name</label>
                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>
                            <input type="text" id={'fullNameForm'} placeholder={'John Doe'} onChange={(e) => {setFullName(e.target.value)}}/>
                        </div>
                    </div>
                    <div className={classes.inputForm}>
                        <label htmlFor="usernameForm">Your Username</label>
                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>
                            <input type="text" id={'usernameForm'} placeholder={'johndoe'} onChange={(e) => {setUsername(e.target.value)}}/>
                        </div>
                    </div>
                    <div className={classes.inputForm}>
                        <label htmlFor="emailForm">Your Email</label>
                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>
                            <input type="email" id={'emailForm'} placeholder={'johndoe@myemail.com'} name={'email'} onChange={(e) => {setEmail(e.target.value)}}/>
                        </div>
                    </div>
                    <div className={classes.inputForm}>
                        <label htmlFor="passwordForm">Your Password</label>
                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>
                            <input type="password" id={'passwordForm'} placeholder={'1321456'} name={'password'}  onChange={(e) => {setLocalPassword(e.target.value)}}/>
                        </div>
                    </div>
                    <div className={classes.inputForm} style={{
                        marginTop: '10px'
                    }}>
                        <div className={classes.buttons_log+" "+classes.blue} onClick={loginUser}>
                            <div><FontAwesomeIcon icon={faEnvelope} /></div>
                            <div>Continue with Email</div>
                            <div></div>
                        </div>
                    </div>
                    <div className={classes.inputForm}>
                        <GoogleLogin
                            clientId={clientId}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                            render={renderProps => (
                                <div className={classes.buttons_log+" "+classes.black} onClick={renderProps.onClick}>
                                    <div><FontAwesomeIcon icon={faGoogle} /></div>
                                    <div>Continue with Google</div>
                                    <div></div>
                                </div>
                            )}
                        />
                    </div>
                </animated.div>
            </div>
        </animated.div>
    );
};

export default SignUpLeftCard;
