import React, {useEffect, useState} from 'react';
import classes from './sortableTable.module.css'
import Icon from "./Icon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort, faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {setAdminBotListSelect, setAdminBotListSort, setAdminSelectedBot} from "../../../../reducers/adminSlice";
import {Link} from "react-router-dom";

const SortableTableBotList = ({botList}) => {
    const dispatch = useDispatch()
    const adminBotListSort = useSelector(state => state.admin.adminBotListSort)
    const [data, setData] = useState(botList);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const getSort = (param) => {
        const currentSort = adminBotListSort;

        if (currentSort === param) {
            dispatch(setAdminBotListSort(`-${param}`));
        } else if (currentSort === `-${param}`) {
            dispatch(setAdminBotListSort(''));
        } else {
            dispatch(setAdminBotListSort(param));
        }
    };

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAllChecked(checked);

        if (checked) {
            const allIds = data.map((item) => item.id);
            setSelectedItems(allIds);
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelect = (itemId) => {
        const isChecked = selectedItems.includes(itemId);
        let updatedItems;

        if (isChecked) {
            updatedItems = selectedItems.filter((id) => id !== itemId);
        } else {
            updatedItems = [...selectedItems, itemId];
        }

        setSelectedItems(updatedItems);
        setSelectAllChecked(updatedItems.length === data.length);
    };

    useEffect(() => {
        setData(botList);
    }, [botList]);

    useEffect(() => {
        dispatch(setAdminBotListSelect(selectedItems))
    }, [selectedItems])

    return (
        <table className={classes.table}>
            <thead>
                <tr>
                    <th style={{
                        textAlign: 'start',
                    }}>
                        <input
                            type="checkbox"
                            name="adminListSelectAll"
                            checked={selectAllChecked}
                            onChange={handleSelectAll}
                        />
                    </th>
                    <th className={classes.title} onClick={() => getSort('id')}>
                        <span style={{marginRight: 5}}>Bot Name</span>
                        <span><FontAwesomeIcon icon={faSort} /></span></th>
                    <th className={classes.title} onClick={() => getSort('username')}>
                        <span style={{marginRight: 5}}>Username</span>
                        <span><FontAwesomeIcon icon={faSort} /></span></th>
                    <th className={classes.title}>
                        <span style={{marginRight: 5}}>Monthly Users</span></th>
                    <th className={classes.title} onClick={() => getSort('update_now')}>
                        <span style={{marginRight: 5}}>Update Date</span>
                        <span><FontAwesomeIcon icon={faSort} /></span></th>
                    <th className={classes.title} onClick={() => getSort('visibility')}>
                        <span style={{marginRight: 5}}>Visibility</span>
                        <span><FontAwesomeIcon icon={faSort} /></span></th>
                    <th className={classes.title} onClick={() => getSort('status')}>
                        <span style={{marginRight: 5}}>Status</span>
                        <span><FontAwesomeIcon icon={faSort} /></span></th>
                </tr>
            </thead>
            <tbody className={classes.body}>
            {data.map((item) => (
                <tr key={item.id} className={classes.tr}>
                    <td>
                        <input
                            type="checkbox"
                            name="adminListSelect"
                            checked={selectedItems.includes(item.id)}
                            onChange={() => handleSelect(item.id)}
                        />
                    </td>
                    <td style={{display: 'flex', alignItems:'center', gap: '10px'}}>
                        <span className={classes.icon}><Icon children={item.id}></Icon></span><Link to={'/admin-panel/bot-update/'}><span style={{fontWeight: 'bold', cursor: 'pointer'}} onClick={() => {dispatch(setAdminSelectedBot(item))}}>{item.full_name}</span></Link>
                    </td>
                    <td >{item.username}</td>
                    <td style={{
                        color: 'grey',
                        border: '1px solid gainsboro',
                        display: 'flex',
                        width: 'min-content',
                        padding: '10px 20px',
                        borderRadius: '15px',
                    }}>{item.monthly_user}</td>
                    <td>{new Date(item.update_now).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })}</td>
                    <td>{item.visibility}</td>
                    <td style={{
                        backgroundColor: item.status==='run'?'rgba(26, 213, 152, 0.3)':'rgba(243, 101, 74, 0.3)',
                        color: item.status==='run'?'rgba(26, 213, 152, 1)':'rgba(243, 101, 74, 1)',
                        width: 'min-content',
                        display: 'flex',
                        padding: '10px 25px',
                        borderRadius: '10px',
                    }}>{item.status}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default SortableTableBotList;