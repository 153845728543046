import React from 'react';
import {useDispatch} from "react-redux";
import classes from "../AdminBotList/AdminBotList.module.css";
import {setAdminUserListSearch} from "../../../reducers/adminSlice";

const AdminUserListTopPanel = () => {
    const dispatch = useDispatch()

    return (
        <div style={{
            display: 'flex',
            padding: '15px 20px',
            backgroundColor: 'rgb(255 255 255 / 25%)',
            border: '1px solid rgba(187, 187, 187, 0.23)',
            borderBottom: 'none',
            borderTopLeftRadius:' 10px',
            borderTopRightRadius: '10px'}}>
            <div className={classes.AdminBotListPanel}>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                }}>
                    Admin User List
                </div>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'end',
                    width: '80%'
                }}>
                    <div className={classes.SearchBox}>
                        <input type="text" placeholder={'Search...'} onChange={(e) => {
                            dispatch(setAdminUserListSearch(e.target.value))
                        }}/>
                    </div>
                    <div className={classes.AddNewBot}>
                        Add New User
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminUserListTopPanel;