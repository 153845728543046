import React from 'react';
import BotSetup from "../../components/BotSetup/BotSetup";
import {useSelector} from "react-redux";
import PrivateBase from "../Base/PrivateBase";

const BotSetupPage = () => {
    const bots = useSelector((state) => state.profile.bots)
    const selectedBot = useSelector((state) => state.profile.selectedBot)

    return (
        <div>
            {selectedBot
                ? <BotSetup></BotSetup>
                : <></>
            }
        </div>
    );
};

export default BotSetupPage;