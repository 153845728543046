import { configureStore } from '@reduxjs/toolkit';
// Reducers
import authReducer from '../reducers/authSlice';
import profileReducer from '../reducers/profileSlice'
import generalReducer from "../reducers/generalSlice";
import docsReducer from "../reducers/docsSlice";
import adminReducer from "../reducers/adminSlice";

export default configureStore({
    reducer: {
        auth:   authReducer,
        profile:   profileReducer,
        general:   generalReducer,
        docs:   docsReducer,
        admin:   adminReducer,
    },
});