import React, {useEffect} from 'react';
import Sidebar from "../../components/General/Sidebar/Sidebar";
import Header from "../../components/General/Header/Header";
import SnackBar from "../../components/General/Snackbar/SnackBar";
import {Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useSpring, animated} from "react-spring";

const PrivateBase = () => {
    const showSidebar = useSelector(state => state.general.showSidebar)
    const [props, set, stop] = useSpring(() => ({width: '95%'}))

    useEffect(() => {
        set({width: "100%"})
    }, [showSidebar])
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            <div style={{
                position: 'fixed',
            }}>
                <Sidebar></Sidebar>
            </div>
            <div style={{marginLeft: 80, width: '100%'}}>
                <animated.div style={props} >
                    <div style={{
                        padding: '0 20px'
                    }}>
                        <Header></Header>
                        <Outlet />
                        <SnackBar></SnackBar>
                    </div>
                </animated.div>
            </div>
        </div>
    );
};

export default PrivateBase;