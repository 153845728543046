import {apiPath} from '../constants';
import axios from "axios";

export default class docsApi {
    static async GetDocsList(){
        return await axios.get(`${apiPath}/docs/list/`,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
}