import React, {useEffect, useRef, useState} from 'react';
import classes from "./styles.module.css";
import {useFetching} from "../../../hooks/useFetching";
import profileApi from "../../../api/profileApi";
import {setBotCount, setBots} from "../../../reducers/profileSlice";
import {useDispatch, useSelector} from "react-redux";
import authApi from "../../../api/authApi";
import axios from "axios";
import {setCookie} from "../../../helpers/helper";
import {setIsAuth, setPassword, setToken, setUser} from "../../../reducers/authSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faUserCheck} from "@fortawesome/free-solid-svg-icons";
import {setMessage, setMessageType} from "../../../reducers/generalSlice";
import DigitBoxForm from "../../General/Forms/DigitBoxForm";


const VerifyEmail = () => {
    const [profilePic, setProfilePic] = useState('')
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user)
    const password = useSelector(state => state.auth.password)
    const [value, setValue] = useState()

    const [verifyEmail, isVerifyEmailLoading, verifyEmailError] = useFetching(async () => {
        const response = await authApi.verifyEmail({
            'char': value,
            'email': user.email,
        })
        if (response.status === 200){
            const data = new FormData();
            data.append('email', user.email);
            data.append('password', password);
            data.append('profile_pic', profilePic);
            data.append('is_google_auth', false);
            const [res_data, res_status] = await authApi.loginUser(data)
            if(res_status === 200) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${res_data.access}`;
                setCookie('token', res_data.token)
                dispatch(setPassword(''))
                dispatch(setToken(res_data.token))
                dispatch(setIsAuth(true))
                dispatch(setUser(res_data.user))
            }
        }
        else if(response.status === 404){
            dispatch(setMessage('Sorry, the validation code you entered is incorrect. Please double-check the code in your email and try again. If you continue to experience issues, please contact our support team for assistance.'))
            dispatch(setMessageType('warning'))
        }
        else if(response.status === 406){
            dispatch(setMessage('Sorry, the validation code you entered has expired. Please request a new code to complete the verification process. You can resend the verification email from your account settings or contact our support team if you need further assistance.'))
            dispatch(setMessageType('warning'))
        }
    })

    return (

        <div className={classes.auth_container}>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 10
            }}>
                <div style={{
                    fontSize: '35px',
                    fontWeight: 'bold',
                }}>
                    <label htmlFor="six-digit-input">Activate Your Account</label>
                </div>
                <DigitBoxForm setChar={setValue}></DigitBoxForm>
                <div className={classes.buttons_log+" "+classes.blue} style={{
                    width: '65%'
                }} onClick={verifyEmail}>
                    <div><FontAwesomeIcon icon={faUserCheck} /></div>
                    <div style={{fontWeight: 'bold'}}>Verify Email</div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;