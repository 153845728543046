import React, {useState} from 'react';
import {Pie} from "react-chartjs-2";

const PieChart = ({chartData}) => {

    return (
        <Pie data={chartData} options={{
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontColor: '#555',
                        padding: 15,
                        // usePointStyle: true,
                        boxWidth: 10,
                    },
                    align: 'start',
                },
            },
        }} />
    );
};

export default PieChart;