import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, MobileStepper, Modal, Paper, TextField, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faUserPlus,
    faXmark,
    faComments,
} from "@fortawesome/free-solid-svg-icons";
import Backdrop from '@mui/material/Backdrop';
import profileApi from "../../../api/profileApi";
import {useFetching} from "../../../hooks/useFetching";
import {useDispatch, useSelector} from "react-redux";
import {setBlueprintList, setBotCount, setBots} from "../../../reducers/profileSlice";

const AddNewBot = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [botToken, setBotToken] = useState('')
    const [botBlueprintSeletected, setBotBlueprintSeletected] = useState('')
    const [blueprintsList, setLocalBlueprintList] = useState([])
    const [addProfileBot, isAddProfileBotsLoading, addProfileBotsError] = useFetching(async () => {
        if(botToken !== ''){
            if(botBlueprintSeletected !== '') {
                const response = await profileApi.AddNewBot({
                    'bot_token': botToken,
                    'bot_blueprint': botBlueprintSeletected,
                })
                dispatch(setBots(response.data))
                dispatch(setBotCount(response.data.length))
                setBotToken('')
                setOpen(false)
            }
        }else{
            setActiveStep(0)
        }
    })

    const [getBlueprintsList, isGetBlueprintsListLoading, getBlueprintsListError] = useFetching(async () => {
        const response = await profileApi.GetBlueprintsList({})
        setLocalBlueprintList(response.data)
        dispatch(setBlueprintList(response.data))
    })
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    useEffect(() => {
        getBlueprintsList()
    }, [])
    return (
        <div className='botContainer'>
            <div className='addNewBotCard' onClick={() => {
                setActiveStep(0)
                setOpen(true)
            }}>
                <FontAwesomeIcon icon={faUserPlus} />
            </div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                closeAfterTransition
            >
                <Box className='AddNewBotCard'>
                    {activeStep===0 && (
                        <div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                                <h3>Creation of new bot</h3>
                                <span onClick={() => setOpen(false)} style={{
                                    textAlign: 'right',
                                    color: 'grey',
                                    padding: '5px 12px',
                                }}><FontAwesomeIcon icon={faXmark}/></span>
                            </div>
                            <div className="snackbar" style={{
                                marginTop: 10,
                                padding: '10px',
                                borderRadius: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 30,
                                backgroundColor:'rgba(204,248,254,0.72)'
                            }}>
                                <FontAwesomeIcon icon={faInfoCircle} style={{
                                    fontSize: 25,
                                    color: '#4339F2',
                                }}></FontAwesomeIcon>
                                Please, provide your bot token.If you haven't got any yet, you can create it in <a className='blueTextModal' href="https://t.me/BotFather" style={{display: 'contents'}} target='_blank'>Bot Father</a> in Telegram.
                            </div>
                            <div style={{
                                marginTop: 15
                            }}>
                                <TextField label="Bot Token" variant="outlined" name={'bot_token'} onChange={event => setBotToken(event.target.value)} sx={{
                                    width: '100%'
                                }}/>
                            </div>
                        </div>
                    )}
                    {activeStep===1 && (
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 10,
                        }}>
                            {blueprintsList.map((blueprint, index) =>
                                <div key={index} className={'blueprintBtn'} onClick={() => {
                                    setBotBlueprintSeletected(blueprint.title)
                                    addProfileBot()
                                }}>
                                    <div dangerouslySetInnerHTML={{__html: blueprint.icon}} style={{
                                        fill: 'white',
                                        height: 'min-content',
                                        display: 'flex',
                                        fontSize: 30
                                    }}></div>
                                    {blueprint.title}
                                </div>
                            )}
                        </div>
                    )}
                    <div style={{
                        display: 'flex',
                        justifyContent:"center",
                        marginTop: 10
                    }}>
                        <MobileStepper
                            variant="dots"
                            steps={2}
                            position="static"
                            activeStep={activeStep}
                            sx={{ maxWidth: '100%', flexGrow: 1, padding: '10px 0'}}
                            nextButton={<div></div>}
                            backButton={<div></div>}
                        />
                        {activeStep!==1 && (
                            <Button onClick={() => {
                                setActiveStep(1)
                            }} variant="contained" style={{
                                backgroundColor: '#02A0FC',
                            }}>Submit</Button>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default AddNewBot;