import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name:   'auth',
    initialState: {
        user:   {},
        token:   null,
        isAuth:  false,
        password:  '',
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setIsAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        removeToken: (state) => {
            state.token = null;
        },
    },
});

export const {
    setUser, setIsAuth,setToken,removeToken, setPassword
} = authSlice.actions;

export default authSlice.reducer;