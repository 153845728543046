import React, {useEffect, useState} from 'react';
import {Avatar, AvatarGroup, Grid, Paper} from "@mui/material";
import './ProfileBotItem.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faPlay, faRotateRight, faStop} from "@fortawesome/free-solid-svg-icons";
import {useFetching} from "../../../hooks/useFetching";
import profileApi from "../../../api/profileApi";
import {setSelectedBot} from "../../../reducers/profileSlice";
import {useDispatch, useSelector} from "react-redux";
import {apiPath} from '../../../constants/index'
import {Link} from "react-router-dom";

const ProfileBotItem = ({bot, isAuthor}) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user)
    const [botStatus, setBotStatus] = useState([])

    useEffect(() => {
        if(bot.status == 'run'){
            setBotStatus(['Running', '#34B53A'])
        }else if (bot.status == 'stop'){
            setBotStatus(['Sleeping', '#FFB200'])
        }else if (bot.status == 'error'){
            setBotStatus(['Error', '#FF3A29'])
        }
    }, [])
    const [stopBot, isStopBotLoading, stopBotError] = useFetching(async () => {
        const response = await profileApi.StopBot(bot.id)
        setBotStatus(['Sleeping', '#FFB200'])
    })
    const [runBot, isRunBotLoading, runBotError] = useFetching(async () => {
        const response = await profileApi.RunBot(bot.id)
        setBotStatus(['Running', '#34B53A'])
    })
    const [restartBot, isRestartBotLoading, restartBotError] = useFetching(async () => {
        const response = await profileApi.RestartBot(bot.id)
        setBotStatus(['Running', '#34B53A'])

    })
    return (
        <div className='botContainer'>
            <div className='botCard' >
                <div className='botCardHeader'>
                    <div style={{width:'100%'}}>
                        <Link to={`${bot.username}/bot-details`} onClick={() => {
                            dispatch(setSelectedBot(bot))
                        }}>
                            <p className='heading' style={{
                                fontSize:25,
                                display:'flex',
                                justifyContent:'space-between',
                                width: '100%',
                                alignItems: 'center'
                            }}>{bot.full_name}
                                <span style={{
                                    display: 'flex',
                                    fontSize: 18,
                                    backgroundColor: '#CCF8FE',
                                    width: 30,
                                    height: 30,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 10,
                                }}>
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                </span>
                            </p>
                        </Link>
                        <a href={`https://t.me/${bot.username}`} className='username' target='_blank' style={{fontSize:10}}>@{bot.username}</a>
                    </div>
                </div>
                <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 25,
                }}>
                    <div>
                        <p className='heading'>{bot.today_user}</p>
                        <a className='username'>
                            Amount of users today
                        </a>
                    </div>
                    <div>
                        <p className='heading'>{bot.highest_user}</p>
                        <a className='username'>
                            Max Users per Day
                        </a>
                    </div>
                    <div>
                        <p className='heading'>{bot.monthly_user}</p>
                        <a className='username'>
                            Users Amount This Month
                        </a>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <div style={{
                        display: 'flex',
                        gap: 5,
                    }}>
                        {isAuthor && (
                            <>
                                {botStatus[0] === 'Sleeping'
                                    ?<div onClick={runBot} className="buttons">
                                        {isRunBotLoading
                                            ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                                            :<FontAwesomeIcon icon={faPlay}/>
                                        }
                                    </div>
                                    :[
                                        <div className="buttons" onClick={stopBot} >
                                            <FontAwesomeIcon icon={faStop} />
                                        </div>,
                                        <div onClick={restartBot} className="buttons">
                                            {isRestartBotLoading
                                                ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                                                :<FontAwesomeIcon icon={faRotateRight}/>
                                            }
                                        </div>

                                    ]}
                            </>
                        )}
                    </div>
                    <div>

                    </div>
                    <div>
                        <p className='heading' style={{
                            color: botStatus[1]
                        }}>{botStatus[0]}</p>
                        <a className='username'>
                            Bot Status Right Now
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileBotItem;
