import React, {useEffect, useState} from 'react';
import LeftSidebarDoc from "../../components/Docs/LeftSidebarDoc";
import classes from "../../components/Docs/docs.module.css";
import DocsMain from "../../components/Docs/DocsMain";
import {useFetching} from "../../hooks/useFetching";
import profileApi from "../../api/profileApi";
import {setBotCount, setBots} from "../../reducers/profileSlice";
import docsApi from "../../api/docsApi";
import RightSidebarDoc from "../../components/Docs/RightSidebarDoc";
import {useDispatch, useSelector} from "react-redux";
import {setDocsCategories} from "../../reducers/docsSlice";
import LandingHeader from "../../components/General/Header/LandingHeader";
import DocsHeader from "../../components/General/Header/DocsHeader";
import '../../styles/App.css'

const DocsOverview = () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.general.language)
    const [getDocsList, isGetDocsListLoading, getDocsListError] = useFetching(async () => {
        const response = await docsApi.GetDocsList()
        dispatch(setDocsCategories(response.data))
    })
    useEffect(() => {
        getDocsList()
    }, [])
    return (
        <div className={classes.mainContainer}>
            <DocsHeader></DocsHeader>
            <div className={language==='uk'?classes.innerMainContainer+" uk":classes.innerMainContainer}>
                <LeftSidebarDoc></LeftSidebarDoc>
                <DocsMain></DocsMain>
                <RightSidebarDoc></RightSidebarDoc>
            </div>
        </div>
    );
};

export default DocsOverview;