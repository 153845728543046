import React, {useEffect, useState} from 'react';
import classes from "./docs.module.css";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedDocument} from "../../reducers/docsSlice";
import { Link } from 'react-scroll';

const LeftSidebarDoc = () => {
    const dispatch = useDispatch()
    const docsList = useSelector(state => state.docs.docsCategories)
    const language = useSelector(state => state.general.language)
    const [statusSidebar, setStatusSidebar] = useState([])
    useEffect(() => {
        let arr = []
        for(let doc of docsList){
            arr.push({
                name: doc.title.us,
                isOpen: true,
            })
        }
        setStatusSidebar(arr)
    }, [docsList])
    return (
        <div className={classes.sidebarContainer}>
            <ul className={classes.categories}>
                {docsList.map((doc, index) =>
                    <li>
                        <ul>
                            <div onClick={() => {
                                setStatusSidebar(statusSidebar.map(el => (el.name === doc.title.us ? {...el, isOpen: !el.isOpen} : el)))
                            }}>{doc.title[language]}</div>
                            {statusSidebar.map((status, ind) =>
                                <span className={classes.heightTrans} style={{
                                    height: (status.name === doc.title.us && status.isOpen) ? 'min-content' : '0px'
                                }}>
                                    {doc.docs_pages.map((docdoc, indexindex) =>
                                        <Link to={docdoc.id}>
                                            <li onClick={() => {
                                                dispatch(setSelectedDocument(docdoc))
                                                    }}><span className={classes.smlkArticle}>{docdoc.title[language]}</span></li>
                                        </Link>
                                    )}
                                </span>
                            )}


                        </ul>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default LeftSidebarDoc;