import React from 'react';
import classes from "./style.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";

const PremiumTable = () => {
    return (
        <div style={{marginTop: '20px',}}>
            <div className={classes.premiumDiv}>
                <h2>Main Difference</h2>
            </div>
            <table className={classes.premiumTable}>
                <tr>
                    <th></th>
                    <th>7 Day Trial</th>
                    <th>Starter Plan</th>
                    <th>Basic Plan</th>
                    <th>Expanded Plan</th>
                </tr>
                <tr>
                    <td style={{color: 'var(--main-grey)'}}>Number of Bots</td>
                    <td>1</td>
                    <td>2</td>
                    <td>4</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td style={{color: 'var(--main-grey)'}}>Max Users per Month</td>
                    <td>150</td>
                    <td>1000</td>
                    <td>10000</td>
                    <td>20000</td>
                </tr>
                <tr>
                    <td style={{color: 'var(--main-grey)'}}>Free Space</td>
                    <td>10mb</td>
                    <td>100mb</td>
                    <td>200mb</td>
                    <td>400mb</td>
                </tr>
                <tr>
                    <td style={{color: 'var(--main-grey)'}}>Advertising Buildo</td>
                    <td><div className={classes.tick}><FontAwesomeIcon icon={faCheck} /></div></td>
                    <td><div className={classes.xmark}><FontAwesomeIcon icon={faXmark} /></div></td>
                    <td><div className={classes.xmark}><FontAwesomeIcon icon={faXmark} /></div></td>
                    <td><div className={classes.xmark}><FontAwesomeIcon icon={faXmark} /></div></td>
                </tr>
            </table>
            <div className={classes.premiumDiv}>
                <h2>Main Difference</h2>
            </div>
            <table className={classes.premiumTable}>
                <tr>
                    <th></th>
                    <th>7 Day Trial</th>
                    <th>Starter Plan</th>
                    <th>Basic Plan</th>
                    <th>Expanded Plan</th>
                </tr>
                <tr>
                    <td style={{color: 'var(--main-grey)'}}>Number of Bots</td>
                    <td>1</td>
                    <td>2</td>
                    <td>4</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td style={{color: 'var(--main-grey)'}}>Max Users per Month</td>
                    <td>150</td>
                    <td>1000</td>
                    <td>10000</td>
                    <td>20000</td>
                </tr>
                <tr>
                    <td style={{color: 'var(--main-grey)'}}>Free Space</td>
                    <td>10mb</td>
                    <td>100mb</td>
                    <td>200mb</td>
                    <td>400mb</td>
                </tr>
                <tr>
                    <td style={{color: 'var(--main-grey)'}}>Advertising Buildo</td>
                    <td><div className={classes.tick}><FontAwesomeIcon icon={faCheck} /></div></td>
                    <td><div className={classes.xmark}><FontAwesomeIcon icon={faXmark} /></div></td>
                    <td><div className={classes.xmark}><FontAwesomeIcon icon={faXmark} /></div></td>
                    <td><div className={classes.xmark}><FontAwesomeIcon icon={faXmark} /></div></td>
                </tr>
            </table>
        </div>
    );
};

export default PremiumTable;