import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useFetching} from "../../hooks/useFetching";
import profileApi from "../../api/profileApi";
import {setBotCount, setBots, setCollaborativeBots,} from "../../reducers/profileSlice";
import classes from "./ProfilePages.module.css";
import BotInfoListCard from "../../components/Profile/ProfileBotList/BotInfoListCard";
import ProfileBotItem from "../../components/Profile/ProfileBotList/ProfileBotItem";
import AddNewBot from "../../components/Profile/ProfileBotList/AddNewBot";


const UserProfileList = () => {
    const dispatch = useDispatch()
    const userBotCount = useSelector((state) => state.profile.botCount)
    const token = useSelector((state) => state.auth.token)
    const botList = useSelector(state => state.profile.bots)
    const collaborativeBots = useSelector(state => state.profile.collaborativeBots)

    const [getProfileBots, isGetProfileBotsLoading, getProfileBotsError] = useFetching(async () => {
        const response = await profileApi.GetProfileBots()
        dispatch(setBotCount(response.data.user_bots.length))
        dispatch(setBots(response.data.user_bots))
        dispatch(setCollaborativeBots(response.data.collaborative_bots))
    })

    useEffect(() => {
        getProfileBots()
    }, [])
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
        }}>
            <div className={classes.card}>
                <BotInfoListCard></BotInfoListCard>
            </div>
            <div style={{
                fontSize: 25,
                width: '100%',
                fontWeight: 'bold',
                color: 'var(--main-grey)'
            }}>My Bots</div>
            <div style={{
                width: '100%',
                display: 'flex',
                gap: 10,
                flexWrap: 'wrap'
            }}>
                {botList.map((bot, index) =>
                    <ProfileBotItem key={index} bot={bot} isAuthor={true}></ProfileBotItem>
                )}
                <AddNewBot></AddNewBot>
            </div>
            {collaborativeBots.length>0 && (
                [<div style={{
                    fontSize: 25,
                    width: '100%',
                    fontWeight: 'bold',
                    color: 'var(--main-grey)'
                }}>Collaborative Bots</div>,
                <div style={{
                width: '100%',
                display: 'flex',
                gap: 10,
                flexWrap: 'wrap'
                }}>
                    {collaborativeBots.map((bot, index) =>
                        <ProfileBotItem key={index} bot={bot} isAuthor={false}></ProfileBotItem>
                    )}
                </div>]
            )}

        </div>
    )
};

export default UserProfileList;