import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
    name:   'profile',
    initialState: {
        bots: [],
        collaborativeBots: [],
        botCount: 0,
        selectedBot: {},
        blueprintList: [],
    },
    reducers: {
        setBots: (state, action) => {
            state.bots = action.payload;
        },
        setCollaborativeBots: (state, action) => {
            state.collaborativeBots = action.payload;
        },
        setBotCount: (state, action) => {
            state.botCount = action.payload;
        },
        setSelectedBot: (state, action) => {
            state.selectedBot = action.payload;
        },
        setBlueprintList: (state, action) => {
            state.blueprintList = action.payload;
        },
        clearSelectedBot: (state, action) => {
            state.selectedBot = {};
        },
    },
});

export const {
    setBots, setBotCount, setSelectedBot, clearSelectedBot,
    setBlueprintList, setCollaborativeBots
} = profileSlice.actions;

export default profileSlice.reducer;