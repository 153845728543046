import React, {useEffect, useState} from 'react';
import FlowPreview from "../../components/Profile/BotDetails/FlowPreview";
import {ReactFlowProvider} from "reactflow";
import {
    Avatar,
    AvatarGroup,
    Box,
    Divider,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select, Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import {apiPath} from "../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faCaretDown, faClockRotateLeft,
    faCodeBranch, faPenToSquare,
    faPlay,
    faRotateRight, faServer, faStar,
    faStop, faUser
} from "@fortawesome/free-solid-svg-icons";
import {useFetching} from "../../hooks/useFetching";
import profileApi from "../../api/profileApi";
import classes from "./ProfilePages.module.css";
import {Link} from "react-router-dom";
import PieChart from "../../components/General/Chart/PieChart";
import {getHumanMB} from "../../helpers/helper";
import DetailChartPanel from "../../components/Profile/BotDetails/DetailChartPanel";

const BotDetailsCode = () => {
    const bot = useSelector(state => state.profile.selectedBot)
    const [userBrunch, setUserBrunch] = useState();
    const [botStatus, setBotStatus] = useState([])
    const user = useSelector((state) => state.auth.user)

    const activityData = {
        labels: ['Used', 'Remaining'],
        datasets: [
            {
                data: [bot.used_activity, 1000-bot.used_activity],
                backgroundColor: ['#FF3A29', '#FFB200'],
                hoverBackgroundColor: ['#FF3A29', '#FFB200'],
                borderColor: ['#FF3A29', '#FFB200'],
                borderWidth: 2,
            },
        ],
    };

    const memoryData = {
        labels: ['System', 'Images', 'Files', 'Available'],
        datasets: [
            {
                data: [getHumanMB(bot.system_used_files), getHumanMB(bot.images_used_size),
                    getHumanMB(bot.files_used_size), getHumanMB(bot.free_size)],
                backgroundColor: ['#FF3A29', '#FFB200', '#34B53A', '#4339F2'],
                hoverBackgroundColor: ['#FF3A29', '#FFB200', '#34B53A', '#4339F2'],
                borderColor: ['#FF3A29', '#FFB200', '#34B53A', '#4339F2'], // Set border color for each segment
                borderWidth: 2,
            },
        ],
    };

    const [stopBot, isStopBotLoading, stopBotError] = useFetching(async () => {
        const response = await profileApi.StopBot(bot.id)
        setBotStatus(['Sleeping', '#FFB200'])
    })
    const [runBot, isRunBotLoading, runBotError] = useFetching(async () => {
        const response = await profileApi.RunBot(bot.id)
        setBotStatus(['Running', '#34B53A'])
    })
    const [restartBot, isRestartBotLoading, restartBotError] = useFetching(async () => {
        const response = await profileApi.RestartBot(bot.id)
        setBotStatus(['Running', '#34B53A'])

    })
    const handleChange = (event) => {
        setUserBrunch(event.target.value);
    };

    useEffect(() => {
        if(bot.status === 'run'){
            setBotStatus(['Running', '#34B53A'])
        }else if (bot.status === 'stop'){
            setBotStatus(['Sleeping', '#FFB200'])
        }else if (bot.status === 'error'){
            setBotStatus(['Error', '#FF3A29'])
        }
    }, [])

    useEffect(() => {
        setUserBrunch(bot.file.user.username)
    }, [bot]);

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
            marginBottom: '15px',
        }}>
            <div className={classes.profileBotPageCont}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                }}>
                    <div>
                        <Avatar alt={bot.full_name} src={bot.profile_pic} />
                    </div>
                    <div style={{ fontWeight: 'bold', color: 'var(--main-black)', fontSize: '20px'}}>{bot.username}</div>
                    <div className={classes.botVisibility}>{bot.visibility}</div>
                </div>
            </div>
            <div style={{
                maxWidth: '80%',
                width: '80%',
                display: 'flex',
            }}>
                <div className={classes.leftCont}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10,
                        }}>
                            <p className='heading' style={{
                                color: botStatus[1]
                            }}>{botStatus[0]}</p>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10,
                        }}>
                            <Link to={'/set-up'} style={{ textDecoration: 'none'}}>
                                <div className='buttons'>
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </div>
                            </Link>
                            {botStatus[0] === 'Sleeping'
                                ?<div onClick={runBot} className="buttons">
                                    {isRunBotLoading
                                        ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                                        :<FontAwesomeIcon icon={faPlay}/>
                                    }
                                </div>
                                :[
                                    <div className="buttons" onClick={stopBot} >
                                        <FontAwesomeIcon icon={faStop} />
                                    </div>,
                                    <div onClick={restartBot} className="buttons">
                                        {isRestartBotLoading
                                            ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                                            :<FontAwesomeIcon icon={faRotateRight}/>
                                        }
                                    </div>
                                ]}
                        </div>
                    </div>
                    <div style={{
                        border: '1px solid rgb(218 226 237 / 58%)',
                        borderRadius: 10,
                        cursor: 'pointer',
                        height: '53vh'
                    }}>
                        <ReactFlowProvider>
                            <FlowPreview botFile={bot.file}></FlowPreview>
                        </ReactFlowProvider>
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        gap: 10,
                    }}>
                        <div style={{
                            border: '1px solid rgb(218 226 237 / 58%)',
                            borderRadius: 10,
                            cursor: 'pointer',
                            width: '70%',
                        }}>
                            <DetailChartPanel></DetailChartPanel>
                        </div>
                        <div style={{
                            border: '1px solid rgb(218 226 237 / 58%)',
                            borderRadius: 10,
                            cursor: 'pointer',
                            width: '30%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                            padding: 20,
                        }}>

                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                maxWidth: '19.44%',
                width: '19.44%',
                display: 'flex',
            }}>
                <div className={classes.rightCont}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}>
                        <div style={{
                            fontWeight: 'bold',
                            fontSize: '17px',
                        }}>About</div>
                        <div className={classes.rightContAboutFeatures}><FontAwesomeIcon icon={faAddressBook} /> <span>Name: {bot.full_name}</span></div>
                        <div className={classes.rightContAboutFeatures}><FontAwesomeIcon icon={faClockRotateLeft} /> <span>Update Date: {bot.update_now}</span></div>
                        <div className={classes.rightContAboutFeatures}><FontAwesomeIcon icon={faStar} /> <span>Blueprint: {bot.blueprint.title}</span></div>
                        <div className={classes.rightContAboutFeatures}><FontAwesomeIcon icon={faUser} /> <span>User: {bot.user.username}</span></div>
                    </div>
                    <Divider />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 15,
                    }}>
                        <div style={{
                            fontWeight: 'bold',
                            fontSize: '17px',
                        }}>Used Activity</div>
                        <div style={{
                            width: '70%',
                            alignSelf: 'center',
                        }}>
                            <PieChart chartData={activityData}></PieChart>
                        </div>
                    </div>
                    <Divider />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 15,
                    }}>
                        <div style={{
                            fontWeight: 'bold',
                            fontSize: '17px',
                        }}>Used Memory</div>
                        <div style={{
                            width: '80%',
                            alignSelf: 'center',
                        }}>
                            <PieChart chartData={memoryData}></PieChart>
                        </div>
                    </div>
                    <Divider />
                </div>
            </div>
        </div>
    );
};

export default BotDetailsCode;
