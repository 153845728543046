import React, {useState} from 'react';
import classes from "./styles.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

const PasswordRecover = ({setUEmail}) => {
    const [localEmail, setLocalEmail] = useState('')
    const setEmail = () => {
        setUEmail(localEmail)
    }
    return (
        <div className={classes.auth_container}>
            <div style={{
                fontSize: '35px',
                fontWeight: 'bold',
            }}>
                Recover Password
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
            }}>
                <input type="email" className={classes.login_input} placeholder='Input Your Email' onChange={(e) => {setLocalEmail(e.target.value)}}/>
            </div>
            <div className={classes.buttons_log+" "+classes.blue} onClick={setEmail}>
                <div><FontAwesomeIcon icon={faEnvelope} /></div>
                <div>Send Recover Password</div>
                <div></div>
            </div>
        </div>
    );
};

export default PasswordRecover;