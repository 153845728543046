import React from 'react';
import {apiPath} from "../../../constants";
import {Link} from "react-router-dom";
import classes from './styles.module.css'

const LandingHeader = () => {
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position:'fixed',
            zIndex: '999',
            top: '0',
            backgroundColor: 'black',
        }}>
            <div style={{
                display: 'flex',
                padding:'15px 0',
                alignItems: 'center',
                fontSize: '20px',
                width: '80%',
                justifyContent: 'space-between',
            }}>
                <div style={{
                    display: 'flex',
                    gap: 15,
                    alignItems: 'center'
                }}>
                    <div style={{
                        width: '70px'
                    }}>
                        <Link to={'/'}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}>
                                <div style={{
                                    width: 35,
                                    height:35,
                                    backgroundColor: 'rgb(255 255 255 / 26%)',
                                    borderRadius: '10px',
                                    position: 'absolute',
                                    zIndex: 1,
                                    marginLeft: '-6px'
                                }}></div>
                                <h1 style={{
                                    fontSize: '22px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    position: 'absolute',
                                    zIndex: 2,
                                }}>Buildo</h1>
                            </div>
                        </Link>
                    </div>
                    <Link to={'/docs'}><div className={classes.thirdLight}>Documentation</div></Link>
                    <Link to={'/docs'}><div className={classes.thirdLight}>Documentation</div></Link>
                    <Link to={'/docs'}><div className={classes.thirdLight}>Documentation</div></Link>
                </div>
                <div style={{
                    display: 'flex',
                    gap: 15,
                    fontSize: 15,
                    alignItems: 'center'
                }}>
                    <Link to={'/login'}><div className={classes.secondaryLight}>Log in</div></Link>
                    <Link to={'/signup'}><div className={classes.SignUp}>Sign up</div></Link>
                </div>
            </div>
        </div>
    );
};

export default LandingHeader;