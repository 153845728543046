import React, {useEffect, useState} from 'react';
import {Handle, Position} from "reactflow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const OpenAIChat = (node) => {
    const [knowledge, setKnowledge] = useState(String(node.data.knowledge))
    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }
    useEffect(() => {
        node.data.knowledge = knowledge
    }, [knowledge])
    return (
        <div className='node openAIChat' >
            <Handle type="target" id="openAIChat" position={Position.Top} isValidConnection={(connection) => {return connection.targetHandle !== 'apiNode'}}/>
            <div><span style={{fontSize:"15px",fontWeight:"bold"}}>OpenAI Chat</span></div>
            <span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
            </span>
            <div className="node_settings">
                <div className='message_txtarea'>
                    <textarea className='nodrag' name="message" draggable={false}
                              onChange={e => setKnowledge(e.target.value)}
                              value={knowledge} placeholder={"Input all information that you want AI to know.Example: Buildo is a powerful Telegram bot generator that allows users to create custom bots. With a simple drag-and-drop interface, users can design and configure their bots in just a few clicks."}></textarea>
                </div>
            </div>
        </div>
    );
};

export default OpenAIChat;