import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faGem, faUser, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import classes from "./style.module.css";
import {faClock} from "@fortawesome/free-regular-svg-icons";

const PaymentStatus = ({payment}) => {
    const user = useSelector(state => state.auth.user)
    return (
        <div style={{width: '100%', display: 'flex', justifyContent:'center', flexDirection:'column', alignItems: 'center', gap: '15px'}}>
            <div style={{width: '70%'}}>
                    {payment.status === 'COMPLETED'
                        ?<div className={'card'} style={{height: 'min-content', padding: '20px 0', gap: '10px', alignItems: 'center', backgroundColor:'var(--main-success)'}}>
                            <div>
                                <FontAwesomeIcon icon={faCheck} style={{fontSize:30}}/>
                            </div>
                            <div>
                                <h3>Payment is completed, Plan is activated</h3>
                                {/*{children}*/}
                            </div>
                        </div>
                        :<div className={'card'} style={{height: 'min-content', padding: '20px 0', gap: '10px', alignItems: 'center', backgroundColor:'var(--main-warning)'}}>
                            <div>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                            <div>
                                <h3>Payment is unfinished</h3>
                                {/*{children}*/}
                            </div>
                        </div>
                    }
            </div>
            <div style={{width: '70%', display: 'flex', gap: 15}}>
                <div className={classes.premiumBillCard}>
                    <div className={classes.icon}><FontAwesomeIcon icon={faUser} /></div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <p>Payment author: <span>{user.email}</span></p>
                        <p>Sign up Date: <span>{user.created_at}</span></p>
                    </div>
                </div>
                <div className={classes.premiumBillCard}>
                    <div className={classes.icon}><FontAwesomeIcon icon={faClock} /></div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <p>Plan activated date: <span>{user.plan_start_date}</span></p>
                        <p>Plan end date: <span>{user.plan_end_date}</span></p>
                    </div>
                </div>
                <div className={classes.premiumBillCard}>
                    <div className={classes.icon}><FontAwesomeIcon icon={faGem} /></div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <p>Plan: <span>{user.premium}</span></p>
                        <p>Cost: <span>{payment.amount} {payment.currency}</span></p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PaymentStatus;