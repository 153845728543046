import React, {useEffect, useRef, useState} from 'react';
import classes from './styles.module.css'
import LandingHeader from "../../components/General/Header/LandingHeader";
import {Link} from "react-router-dom";
import {
    faArrowDown,
    faBolt,
    faChartPie, faCubes, faEarthEurope, faFileSignature,
    faLaptopCode,
    faLock,
    faPuzzlePiece,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {apiPath} from "../../constants";
import FakeCodeGenerator from "../../components/General/FakeCode/FakeCodeGenerator";
import AdvantagesContainerLanding from "../../components/Landing/advantagesContainerLanding";
import FlowPreview from "../../components/Profile/BotDetails/FlowPreview";
import {ReactFlowProvider} from "reactflow";


const Landing = () => {
    const sliderRef = useRef(null)
    const slideDown = () => {
        sliderRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.container}>
            <LandingHeader></LandingHeader>
            <section className={classes.block} style={{backgroundColor: 'rgb(251, 251, 253)'}}>
                <div className={classes.first_slide_m}>
                    <h1 className={classes.title+' '+classes.textGradient}>The Revolutionary Telegram Bot Builder to Supercharge Your <b>Productivity</b> and <b>Creativity</b></h1>
                    <div className={classes.containerCardLanding}>
                        <div className={classes.cardLanding}>
                            <FontAwesomeIcon icon={faLaptopCode} />
                            <div className={classes.title_car_slide_landing}>User-friendly</div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <p>No coding required to create Telegram Bot</p>
                            </div>
                        </div>
                        <div className={classes.cardLanding}>
                            <FontAwesomeIcon icon={faBolt} />
                            <div className={classes.title_car_slide_landing}>Lightning Speed</div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <p>Instantly create custom Telegram bots</p>
                            </div>
                        </div>
                        <div className={classes.cardLanding}>
                            <FontAwesomeIcon icon={faChartPie} />
                            <div className={classes.title_car_slide_landing}>Analytics</div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <p>Insights from data for informed decisions</p>
                            </div>
                        </div>
                        <div className={classes.cardLanding}>
                            <FontAwesomeIcon icon={faLock} />
                            <div className={classes.title_car_slide_landing}>Security</div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <p>Protect your Telegram bots with confidence</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.scroll_arr_dowm} onClick={slideDown}>
                        <FontAwesomeIcon icon={faArrowDown} bounce className={classes.scroll}/>
                    </div>
                </div>
            </section>
            <section className={classes.block+' '+classes.fourth_slide_section} style={{backgroundColor: 'white', position: 'relative'}} ref={sliderRef}>
                <div style={{display: 'flex', justifyContent: 'center', height: '100%', width: '100%', flexDirection:'column'}}>
                    <div className={classes.fourth_slide}>
                        <div className={classes.info}>
                            <h1 className={classes.textGradient}>The <b>No-Coding</b> Telegram Bot Builder</h1>
                            <p>Create custom Telegram bots quickly and easily with Buildo, the no-coding Telegram bot builder. Say goodbye to the frustration and complexity of building bots from scratch.</p>
                        </div>
                        <div className={classes.code_img}>
                            <img src={apiPath+"/static/images/Telegram Bot Code Example5.png"} alt="" width={'100%'} />
                        </div>
                    </div>
                </div>
            </section>
            <section className={classes.block} style={{backgroundColor: 'black', position: 'relative'}}>
                <div style={{width: '100%', height: '100%', display: 'flex'}}>
                    <FakeCodeGenerator></FakeCodeGenerator>
                    <div className={classes.fakeCodeBlock}>
                        <div className={classes.textGradient}>Create <b>Powerful Bots</b> in Minutes</div>
                        <p>
                            Buildo is the ultimate Telegram Bot Builder service that enables you to create powerful bots in minutes. With Buildo, you can easily build, customize and deploy your own Telegram bots without any coding skills.
                        </p>
                        <img src={apiPath+"/static/images/buildo_laptop.png"} alt=""/>
                    </div>
                </div>
            </section>
            <section className={classes.block} style={{backgroundColor: 'black'}}>
                <div className={classes.third_slide}>
                    <div className={classes.title}>
                        <div style={{padding:15}}>
                            <h1 className={classes.textGradient}>Unlock Your Potential with Our <b>Powerful Instrument Services</b></h1>
                            <p>Our Telegram Bot Builder offers powerful instrument services tailored to meet your unique needs, with expert repair, maintenance, rental, and sales solutions to help you unlock your full potential. Our team of dedicated professionals provides exceptional results and customer service, empowering you to achieve your goals with confidence.</p>
                        </div>
                        <div></div>
                    </div>
                    <div className={classes.plusesCont}>
                        <div style={{width: '100%', display: 'flex'}}>
                            <AdvantagesContainerLanding arr={[
                                {
                                    icon: faPuzzlePiece,
                                    title: 'Constructor',
                                    description: 'Create commands by simply moving actions.',
                                    pluses: ['Text',
                                        'Image',
                                        'Video',
                                        'Audio',
                                        'Document',
                                        'Variables',
                                        'Keyboards',
                                        'Other...']
                                },
                                {
                                    icon: faStar,
                                    title: 'Integration with other services',
                                    description: 'Buildo can be integrated with various services.',
                                    pluses: ['OpenAI',
                                        'Google Sheets',
                                        'Other...']
                                },
                                {
                                    icon: faChartPie,
                                    title: 'Analytics',
                                    description: 'View new records every day.',
                                    pluses: ['Bot usage',
                                        'Engagement',
                                        'Other metrics',
                                        'Other...']
                                },
                                {
                                    icon: faEarthEurope,
                                    title: 'Multi-language support',
                                    description: 'Create bot in any language.',
                                    pluses: ['Build bots in different languages',]
                                },
                                {
                                    icon: faBolt,
                                    title: 'Custom API integrations',
                                    description: 'Custom APIs and webhooks integrate.',
                                    pluses: ['Integrating APIs and webhooks',
                                    'Custom endpoints']
                                },
                                {
                                    icon: faFileSignature,
                                    title: 'Moderation',
                                    description: 'Managing user-generated content and interactions.',
                                    pluses: ['Create Roles and Admins',
                                        'Blocking users']
                                },
                                {
                                    icon: faCubes,
                                    title: 'Custom templates',
                                    description: 'Customizing bot\'s look and feel.',
                                    pluses: ['Creating Custom Templates']
                                }
                            ]}></AdvantagesContainerLanding>
                        </div>
                    </div>
                </div>
            </section>
            <section className={classes.block} style={{backgroundColor: 'white', position: 'relative'}}>
                <div className={classes.fifth_slide}>
                    <div className={classes.fourth_slide}  style={{gap: '80px'}}>
                        <div className={classes.code_img}>
                            <img src={apiPath+"/static/images/Buildo Builder Canvas.png"} alt="" width={'100%'} style={{
                                opacity: '80%',
                                borderRadius: '15px',
                                border: '1px solid #c1c1c157',
                            }}/>
                        </div>
                        <div className={classes.info}>
                            <h1 className={classes.textGradient}>Want to <b>try demo</b> right now?</h1>
                            <p>Try out the demo version and experience the most convenient and secure way to accept crypto payments!</p>
                            <Link to={'/login'}><span>Try now!</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Landing;