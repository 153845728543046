import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import Landing from "../pages/Landing/Landing";
import LoginPage from "../pages/Landing/LoginPage";
import DocsOverview from "../pages/Docs/DocsOverview";
import PasswordRecoverPage from "../pages/Landing/PasswordRecoverPage";
import SignUpPage from "../pages/Landing/SignUpPage";


const Public = () => {
    return (
        <Routes>
            <Route path='/' element={<Landing/>} />
            <Route path='/login' element={<LoginPage/>} />
            <Route path='/signup' element={<SignUpPage/>} />
            <Route path='/recover-password' element={<PasswordRecoverPage/>} />
            <Route path='docs' element={<DocsOverview/>} />
        </Routes>
    );
};

export default Public;
