import React from 'react';
import classes from "./style.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
const LoadingDots = () => {
    return (
        <div className={classes.Loading}>
            <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
    );
};

export default LoadingDots;