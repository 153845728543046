import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearMessage, clearMessageType} from "../../../reducers/generalSlice";
import classes from "./Snackbar.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faInfoCircle, faTriangleExclamation, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useSpring, animated} from 'react-spring'
import {useTimeout} from "../../../hooks/useTimeout";

const SnackBar = () => {
    const dispatch = useDispatch()
    const [title,message] = useSelector((state) => state.general.message).split('||')
    const message_type = useSelector((state) => state.general.messageType)
    const [icon, setIcon] = useState()
    const [classesSnack, setClassesSnack] = useState()
    const [props, set, stop] = useSpring(() => ({opacity: 0, bottom:0, display:'none'}))

    const handleClose = () => {
        set({bottom: 0, opacity: 0,display:'none'})
        dispatch(clearMessage())
        dispatch(clearMessageType())
    }
    const [closeSleep, isCloseSleep, serverCloseError] = useTimeout(() =>{
        handleClose()
    }, 10000)
    useEffect(() => {
        closeSleep()
        if(title && message && message_type){
            set({display: 'flex', opacity: 1, bottom: 50})
         }
    }, [title,message,message_type])
    useEffect(() => {
        if (message_type==='success') {
            setIcon(faCheckCircle)
            setClassesSnack(classes.Snackbar+" "+classes.success)
        } else if (message_type==='info') {
            setIcon(faInfoCircle)
            setClassesSnack(classes.Snackbar+" "+classes.info)
        } else if (message_type==='error'){
            setIcon(faTriangleExclamation)
            setClassesSnack(classes.Snackbar+" "+classes.error)
        }
    }, [message_type])
    return (
        <animated.div className={classes.SnackbarContainer} style={props}>
            <div className={classesSnack}>
                <FontAwesomeIcon icon={icon} style={{
                    fontSize: '50px'
                }}/>
                <div>
                    <div style={{
                        fontSize: '20px',
                        fontWeight: 'bold'
                    }}>
                        {title}
                    </div>
                    <div>{message}</div>
                </div>
                <div className={classes.closeButton} onClick={handleClose}>
                    Close
                </div>
            </div>
        </animated.div>
    );
};

export default memo(SnackBar);