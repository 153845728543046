import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
    name:   'admin',
    initialState: {
        adminBotListSort: 'id',
        adminBotListSearch: '',
        adminBotListSelect: [],
        adminUserListSort: 'id',
        adminUserListSearch: '',
        adminSelectedBot: {},
    },
    reducers: {
        setAdminBotListSort: (state, action) => {
            state.adminBotListSort = action.payload;
        },
        setAdminBotListSearch: (state, action) => {
            state.adminBotListSearch = action.payload;
        },
        setAdminBotListSelect: (state, action) => {
            state.adminBotListSelect = action.payload;
        },
        setAdminUserListSort: (state, action) => {
            state.adminUserListSort = action.payload;
        },
        setAdminUserListSearch: (state, action) => {
            state.adminUserListSearch = action.payload;
        },
        setAdminSelectedBot: (state, action) => {
            state.adminSelectedBot = action.payload;
        },
    },
});

export const {
    setAdminBotListSort,
    setAdminBotListSearch,
    setAdminUserListSearch,
    setAdminUserListSort,
    setAdminBotListSelect,
    setAdminSelectedBot,
} = adminSlice.actions;

export default adminSlice.reducer;