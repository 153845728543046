import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/General/Sidebar/Sidebar";
import Header from "../../components/General/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import FlowPreview from "../../components/Profile/BotDetails/FlowPreview";
import {ReactFlowProvider} from "reactflow";
import DetailChartPanel from "../../components/Profile/BotDetails/DetailChartPanel";
import classes from './ProfilePages.module.css'
import {Box, Tab, Tabs} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodePullRequest, faLaptopCode} from "@fortawesome/free-solid-svg-icons";
import styles from "../../components/General/Sidebar/sidebar.module.css";
import BotDetailsCode from "./BotDetailsCode";
import BotDetailsContribution from "./BotDetailsContribution";
import {useNavigate, useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import profileApi from "../../api/profileApi";
import {setSelectedBot} from "../../reducers/profileSlice";
import LoadingDots from "../../components/General/Loading/LoadingDots";

const BotDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { slug } = useParams();
    const selectedBot = useSelector(state => state.profile.selectedBot)
    const [value, setValue] = useState(0);
    const [botDetailsLoading, setBotDetailsLoading] = useState(true)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [getBotDetails, isGetBotDetailsLoading, getBotDetailsError] = useFetching(async () => {
        const response = await profileApi.GetBotDetails(slug)
        dispatch(setSelectedBot(response.data))
    })

    useEffect(() => {
        if(Object.keys(selectedBot).length === 0){
            getBotDetails()
            setBotDetailsLoading(false)
        }
    }, [])

    useEffect(() => {
        if (getBotDetailsError === "Request failed with status code 404"){
            navigate('/404')
        }
    }, [getBotDetailsError]);

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            gap: 20
        }}>

            <BotDetailsCode></BotDetailsCode>
        </div>
    );
};

export default BotDetails;
