import UserProfileList from "../pages/ProfilePages/UserProfileList";
import BotSetupPage from "../pages/ProfilePages/BotSetupPage";
import BotDetails from "../pages/ProfilePages/BotDetails";
import PrivateBase from "../pages/Base/PrivateBase";
import ShopCatalog from "../pages/Shop/ShopCatalog";
import DocsOverview from "../pages/Docs/DocsOverview";
import UserSettings from "../pages/Settings/UserSettings";
import React, { useEffect, useState } from 'react';
import {Navigate, Routes, Route, useNavigate, Outlet} from 'react-router-dom';
import {setBots, setSelectedBot} from "../reducers/profileSlice";
import {useDispatch, useSelector} from "react-redux";
import AdminBase from "../pages/Base/AdminBase";
import AdminOverview from "../pages/Admin/AdminOverview";
import AdminPageBotList from "../pages/Admin/AdminPageBotList";
import NotFound from "../pages/NotFound/NotFound";
import AdminPageUserList from "../pages/Admin/AdminPageUserList";
import AdminPageBotUpdate from "../pages/Admin/AdminPageBotUpdate";
import PremiumPlanPage from "../pages/Payment/PremiumPlanPage";
import PremiumPlanLoader from "../pages/Payment/PremiumPlanLoader";
import OrderStatus from "../pages/Payment/OrderStatus";
import RefreshTokenBase from "../pages/Base/RefreshTokenBase";
import AddContributorPage from "../pages/ProfilePages/AddContributorPage";


const Public = () => {
    const user = useSelector(state => state.auth.user)

    return (
        <Routes>
            <Route path="/" element={<RefreshTokenBase />}>
                <Route element={<PrivateBase />}>
                    <Route index element={<UserProfileList />} />
                    <Route path=":slug/bot-details" element={<BotDetails />} />
                    <Route path=":slug/set-up" element={<BotSetupPage />} />
                    <Route path="shop" element={<ShopCatalog />} />
                    <Route path="settings" element={<UserSettings />} />
                    <Route path="plan" element={<PremiumPlanPage />} />
                    <Route path="plan-redirector" element={<PremiumPlanLoader />} />
                    <Route path="plan-status/:slug" element={<OrderStatus />} />
                </Route>
                {user.is_superuser && (
                <Route index element={<AdminOverview />}>
                    <Route path="bot-list" element={<AdminPageBotList />} />
                    <Route path="bot-update" element={<AdminPageBotUpdate />} />
                    <Route path="user-list" element={<AdminPageUserList />} />
                </Route> )}
                <Route path="add-contributor/:slug" element={<AddContributorPage />}/>
                <Route path="docs" element={<DocsOverview />} />
                <Route path="404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};

export default Public;
