import React from 'react';
import HeaderCategories from "../../components/Shop/Catalog/HeaderCategories";

const ShopCatalog = () => {
    return (
        <div>
            <HeaderCategories />
        </div>
    );
};

export default ShopCatalog;
