import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faCheck, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {
    Badge, Button, Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popper,
    styled
} from "@mui/material";
import {useFetching} from "../../../hooks/useFetching";
import classes from "./styles.module.css";

import messagesApi from "../../../api/messagesApi";
import {frontPath} from "../../../constants";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        backgroundColor: 'var(--main-primary)'
    },
}));
const Messages = () => {
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [messageList, setMessageList] = useState([])
    const [messageAmount, setMessageAmount] = useState(0)
    const [tempMessageList, setTempMessageList] = useState([])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
        readAllMessages()
    };
    const [getMessageList, isGetMessageListLoading, isGetMessageError] = useFetching(async () => {
        const response = await messagesApi.GetMessagesList()
        setMessageList(response.data.messages)
        setMessageAmount(response.data.message_to_read_amount)
    })
    const [readAllMessages, isReadAllMessagesLoading, readAllMessagesError] = useFetching(async () => {
        const response = await messagesApi.ReadAllMessages({
            'messages_ids': messageList
        })
        setMessageAmount(0)
        setMessageList(response.data)
    })
    const [deleteAllMessages, isDeleteAllMessagesLoading, deleteAllMessagesError] = useFetching(async () => {
        const response = await messagesApi.DeleteAllMessages({
            'messages_ids': tempMessageList
        })
        setMessageList(response.data)
    })
    useEffect(() =>{getMessageList()}, [])
    useEffect(() => {
        let id = setInterval(() => {
            getMessageList()
        }, 30000);
        return () => clearInterval(id);
    }, []);
    return (
        <div>
            <IconButton aria-label="cart" onClick={handleClick}>
                <StyledBadge badgeContent={messageAmount} color="secondary" max={10}>
                    <FontAwesomeIcon icon={faBell} />
                </StyledBadge>
            </IconButton>
            <Popper open={open} anchorEl={anchorEl} id={'transition-popper'} placement={'bottom-end'} sx={{
                zIndex: '9999'
            }}>
                <div className={classes.messageBox}>
                    <List style={{width:'100%'}}>
                        <ListItem>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                            }}>
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        color: 'var(--main-black)',
                                        fontSize: '20px',
                                    }}
                                >Recent Messages</div>
                                <Button size="small" onClick={() => {
                                    setTempMessageList(messageList)
                                    deleteAllMessages()
                                }}>Clear All</Button>
                            </div>

                        </ListItem>
                        {messageList.map((message, index) =>
                            [<Divider />,
                            <div style={{
                                padding: '15px'
                            }}>
                                {message.is_invitation
                                ?<ListItem disablePadding secondaryAction={
                                    <div style={{
                                        display: 'flex',
                                        gap: 7
                                    }}>
                                        <IconButton edge="end" aria-label="delete" onClick={() => {
                                            window.location.href=`${frontPath}add-contributor/${message.bot.contribute_char}`
                                        }}>
                                            <FontAwesomeIcon icon={faCheck} style={{
                                                width: '15px',
                                                color: 'var(--main-success)',
                                                height: '15px'
                                            }}/>
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={() => {
                                            setTempMessageList([message])
                                            deleteAllMessages()
                                        }}>
                                            <FontAwesomeIcon icon={faXmark} style={{
                                                width: '15px',
                                                color: 'var(--main-danger)',
                                                height: '15px'
                                            }}/>
                                        </IconButton>
                                    </div>
                                }>
                                    <div style={{
                                        width: '260px'
                                    }}>
                                        <ListItemText
                                            primary={message.title}
                                        />
                                    </div>
                                </ListItem>
                                :<ListItem disablePadding secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => {
                                            setTempMessageList([message])
                                            deleteAllMessages()
                                        }}>
                                            <FontAwesomeIcon icon={faXmark} style={{
                                                width: '15px',
                                                color: 'var(--main-grey)',
                                                height: '15px'
                                            }}/>
                                        </IconButton>
                                    }>
                                        <div style={{
                                            width: '260px'
                                        }}>
                                            <ListItemText
                                                primary={message.title}
                                            />
                                        </div>
                                    </ListItem>
                                }
                            </div>]
                        )}
                        <Divider />
                        <ListItem>
                            <div style={{ color: 'var(--main-grey)', }}
                            >You can follow us on Twitter or subscribe on our blog to receive exclusive tips and updates about Buildo</div>
                        </ListItem>
                    </List>
                </div>

            </Popper>
        </div>
    );
};

export default Messages;