import React from 'react';
import classes from "./style.module.css";
import {useFetching} from "../../../hooks/useFetching";
import paymentApi from "../../../api/paymentApi";
import {setCookie} from "../../../helpers/helper";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setMessage, setMessageType} from "../../../reducers/generalSlice";

const PremiumItem = ({obj}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [createPayment, isCreatePayment, createPaymentError] = useFetching(async () => {
        const response = await paymentApi.CreatePayment({
            "plan": obj.plan,
            "amount": obj.prTag,
        })
        if(response.status === 200){
            setCookie('paymentUid', response.data.uid)
            window.location.href = response.data.url;
            return null;
        }else if(response.status === 202){
            navigate(`/plan-status/${response.data.uid}`)
            dispatch(setMessageType(response.data.status))
            dispatch(setMessage(response.data.message))
        }else if(response.status === 204){
            dispatch(setMessageType(response.data.status))
            dispatch(setMessage(response.data.message))
        }
    })

    return (
        <div className={classes.premiumItem} onClick={createPayment}>
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                <div style={{width: '50%'}}>
                    <h2>{obj.title}</h2>
                    <small style={{color: 'var(--main-grey)'}}>{obj.spanMotivated}</small>
                </div>
                <div className={classes.price}>
                    <h3>{obj.price}</h3>
                </div>
            </div>
            <div style={{textAlign:'center', fontSize: 20}}>
                <span style={{fontWeight:'bold'}}>{obj.allowBCount}</span><span style={{color: 'var(--main-grey)'}}> Bots Allowed</span>
            </div>
            <div style={{textAlign:'center', fontSize: 20}}>
                <span style={{fontWeight:'bold'}}>{obj.allowedUM}</span><span style={{color: 'var(--main-grey)'}}> Users per Month</span>
            </div>
            <div style={{textAlign:'center', fontSize: 20}}>
                <span style={{fontWeight:'bold'}}>{obj.mbAllowed}</span><span style={{color: 'var(--main-grey)'}}> Free Space</span>
            </div>
        </div>
    );
};

export default PremiumItem;
