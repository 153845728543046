import {apiPath} from '../constants';
import axios from "axios";

export default class botStaticApi {
    static async UploadBotPostImg(data){
        return await axios.post(`${apiPath}/media/upload_post_img/`, data,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
    static async UploadBotPostFile(data){
        return await axios.post(`${apiPath}/media/upload_post_file/`, data,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
    static async DeleteBotPostImg(data){
        return await axios.delete(`${apiPath}/media/delete_img/${data}/`,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
    static async DeleteBotPostFile(data){
        return await axios.delete(`${apiPath}/media/delete_file/${data}/`,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
}