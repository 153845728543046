import { createSlice } from '@reduxjs/toolkit';

export const docsSlice = createSlice({
    name:   'docs',
    initialState: {
        docsCategories: [],
        selectedDoc : {},
    },
    reducers: {
        setDocsCategories: (state, action) => {
            state.docsCategories = action.payload;
        },
        setSelectedDocument: (state, action) => {
            state.selectedDoc = action.payload;
        },
    },
});

export const {
    setDocsCategories, setSelectedDocument
} = docsSlice.actions;

export default docsSlice.reducer;