import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "axios";
import {useFetching} from "../../hooks/useFetching";
import authApi from "../../api/authApi";
import {useSelector} from "react-redux";

const RefreshTokenBase = () => {
    const refreshToken = useSelector(state => state.auth.token)


    const [getRefreshToken, isGetRefreshTokenLoading, getRefreshTokenError] = useFetching(async () => {
        const response = await authApi.refreshToken({
            'refresh_token': refreshToken,
        })
        if (response.status === 200){
            const newAccessToken = response.data.access_token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(newAccessToken)}`;
        }
    })

    useEffect(() => {
        const interval = setInterval(() => {
            getRefreshToken()
        }, (60 * 30) * 1000);
    }, []);

    return (
        <Outlet></Outlet>
    );
};

export default RefreshTokenBase;