import React, {useEffect, useState} from 'react';
import classes from './style.module.css'
import auth_classes from '../Auth/Oauth/styles.module.css'
import {useDispatch, useSelector} from "react-redux";
import {useFetching} from "../../hooks/useFetching";
import authApi from "../../api/authApi";
import {removeToken, setIsAuth, setUser} from "../../reducers/authSlice";
import axios from "axios";
import {faFloppyDisk, faUserCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import userApi from "../../api/userApi";
import DigitBoxForm from "../General/Forms/DigitBoxForm";
import LoadingDots from "../General/Loading/LoadingDots";
const UserEditForm = () => {
    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch()
    const [uFullName, setUFullName] = useState(String(user.full_name))
    const [uEmail, setUEmail] = useState(String(user.email))
    const [uOpenAiToken, setUOpenAiToken] = useState(String(user.openAI_token))
    const [isEmailChange, setIsEmailChange] = useState(false)
    const [charValue, setCharValue] = useState()

    const [getUser, isGetUserLoading, getUserError] = useFetching(async () => {
        const response = await authApi.getUser()
        if (response.status === 200){
            dispatch(setUser(response.data))
            dispatch(setIsAuth(true))
        }else{
            dispatch(removeToken())
            delete axios.defaults.headers.common["Authorization"];
        }
    })
    const [updateUser, isUpdatingUserLoading, updateUserError] = useFetching(async () => {
        const response = await userApi.UpdateUserProfile({
            "full_name": uFullName,
            "email": uEmail,
            "openAI_token": uOpenAiToken
        })
        if (response.data.email_changed){
            setIsEmailChange(true)
        }else{
            getUser()
        }
    })
    const [verifyNewEmail, isVerifyNewEmailLoading, VerifyNewEmailError] = useFetching(async () => {
        const response = await userApi.VerifyNewEmail({
            'char': charValue,
        })
        if (response.status === 200){
            getUser()
            setIsEmailChange(false)
        }
    })
    useEffect(() => {
        getUser()
    }, []);

    return (
        <>
            {!isGetUserLoading
                ?<>
                    {!isEmailChange
                        ?<div className={classes.editCard}>
                            <div>
                                Good Morning, <span style={{fontWeight:'bold'}}>{user.full_name
                                ?user.full_name
                                :user.email}</span>
                            </div>
                            <input type="text" className={classes.editInput} placeholder={'Your Full Name'} value={uFullName}
                                   onChange={(e) => setUFullName(e.target.value)}/>
                            <div className={classes.hr}/>
                            <input type="text" className={classes.editInput} placeholder={'Your Email'} value={uEmail}
                                   onChange={(e) => setUEmail(e.target.value)}/>
                            <input type="text" className={classes.editInput} placeholder={"Your Open Ai Token"} value={uOpenAiToken}
                                   onChange={(e) => setUOpenAiToken(e.target.value)}/>
                            <button className={classes.saveButton} onClick={updateUser}><FontAwesomeIcon icon={faFloppyDisk} /> Save</button>
                        </div>
                        :<div className={auth_classes.auth_container}>
                            <div style={{
                                fontSize: '35px',
                                fontWeight: 'bold',
                            }}>
                                <label htmlFor="six-digit-input">Verify Your New Email</label>
                            </div>
                            <div>
                                <DigitBoxForm setChar={setCharValue}></DigitBoxForm>
                            </div>
                            <div>
                                <button className={classes.saveButton} onClick={verifyNewEmail}><FontAwesomeIcon icon={faUserCheck} /> Verify</button>
                            </div>
                        </div>
                    }
                </>
                :<LoadingDots></LoadingDots>
            }
        </>
    );
};

export default UserEditForm;