import React from 'react';
import {apiPath} from "../../constants";
import {faGem} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PremiumList from "../../components/Profile/PremiumPlan/PremiumList";
import PremiumTable from "../../components/Profile/PremiumPlan/PremiumTable";

const PremiumPlanPage = () => {
    return (
        <div style={{width: '100%', display: 'flex', justifyContent:'center'}}>
            <div style={{width: '70%'}}>
                <div className={'card'} style={{height: 'min-content', padding: '20px 0', gap: '10px', alignItems: 'center'}}>
                    <div>
                        <FontAwesomeIcon icon={faGem} style={{fontSize:30}}/>
                    </div>
                    <div>
                        <h3>Select Premium Plan</h3>
                        {/*{children}*/}
                    </div>
                </div>
                <PremiumList></PremiumList>
                <PremiumTable></PremiumTable>
            </div>
        </div>
    );
};

export default PremiumPlanPage;