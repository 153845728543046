import React, {useEffect, useState} from 'react';
import {Handle, Position} from "reactflow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown, faGear, faMinus, faPlus, faUserSecret, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {setMessage, setMessageType} from "../../../../reducers/generalSlice";

const PollNode = (node) => {
    const dispatch = useDispatch()
    const [questionName, setQuestionName] = useState(String(node.data.question))
    const [jsonObj, setJsonObj] = useState(node.data.jsonObj);
    const [questionsFields, setQuestionsFields] = useState(node.data.questionsFields);
    const [isAnonymous, setIsAnonymous] = useState(node.data.isAnonymous);
    const [isQuiz, setIsQuiz] = useState(node.data.isQuiz);
    const [quizAnswer, setQuizAnswer] = useState(node.data.quizAnswer);

    useEffect(() => {
        node.data.question = questionName
        node.data.jsonObj = jsonObj
        node.data.isAnonymous = isAnonymous
        node.data.questionsFields = questionsFields
        node.data.isQuiz = isQuiz
        node.data.quizAnswer = quizAnswer
    }, [questionName, jsonObj, isAnonymous, questionsFields,quizAnswer, isQuiz])

    const handleCreateJson = () => {
        const newJsonObj = questionsFields.map(({ value }) => value.trim()).filter(value => value !== '');
        setJsonObj(newJsonObj);
    };

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        setQuestionsFields(prevFields => {
            const newFields = [...prevFields];
            newFields[index] = { value };
            return newFields;
        });
    };

    useEffect(() => {
        handleCreateJson();
    }, [questionsFields])

    const handleRemoveClick = (index) => {
        const newInputFields = [...questionsFields];
        newInputFields.splice(index, 1);
        setQuestionsFields(newInputFields);
        const { [newInputFields[index].key]: _, ...newObj } = jsonObj;
        setJsonObj(newObj);
    };

    const handleAddFields = () => {
        setQuestionsFields([...questionsFields, { value: "" }]);
    };

    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }

    return (
        <div className='node message_node poll_node' style={{width: 250}}>
            <Handle type="target" id="message_node" position={Position.Top}/>
            <div><span style={{fontSize:"15px",fontWeight:"bold"}}>Poll</span></div>
            <span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
            </span>
            <div style={{display: "flex", marginTop: 10, gap: 5}}>
                <div
                    className={`icon_settings nodrag ${isAnonymous? 'active' : ''}`}
                    title={`${isAnonymous? 'Poll Is Anonymous' : 'Poll Is Public'}`}
                    onClick={() => {setIsAnonymous(!isAnonymous)}}
                >
                    <FontAwesomeIcon icon={faUserSecret} />
                </div>
                <div
                    className={`icon_settings nodrag ${isQuiz? 'active' : ''}`}
                    title={`${isQuiz? 'Quiz Activated' : 'Quiz Is Deactivated'}`}
                    onClick={() => {setIsQuiz(!isQuiz)}}
                >
                    <FontAwesomeIcon icon={faCrown} />
                </div>
            </div>
            <div style={{display: "flex", marginTop: 10, flexDirection: "column", gap: 5}}>
                <div style={{display: "flex"}}>
                    <input type="text" className='question nodrag' placeholder="Question" onChange={e => setQuestionName(e.target.value)} value={questionName} />
                </div>
                {questionsFields.map((inputField, index) => (
                    <div style={{display: "flex", gap:5}}>
                        {isQuiz
                        ?<input
                                type="radio"
                                name={'answer'+node.id}
                                className='nodrag'
                                title={'Choose correct answer'}
                                style={{width: '10px'}}
                                checked={index === quizAnswer}
                                onChange={() => {
                                    setQuizAnswer(index)
                                }}
                            />
                        :<></>
                        }
                        <input
                            type="text"
                            className='question nodrag'
                            placeholder={'Option'}
                            value={inputField.value}
                            onChange={(event) => handleInputChange(event, index)}
                        />
                        <div className="minus" onClick={() => handleRemoveClick(index)}>
                            <FontAwesomeIcon icon={faMinus} />
                        </div>
                    </div>
                ))}
                <div className={'plus nodrag'} onClick={handleAddFields}>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </div>
        </div>
    );
};

export default PollNode;