import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useFetching} from "../../../hooks/useFetching";
import authApi from "../../../api/authApi";
import classes from "./styles.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUnlock, faUserCheck} from "@fortawesome/free-solid-svg-icons";
import DigitBoxForm from "../../General/Forms/DigitBoxForm";
import {setMessage, setMessageType} from "../../../reducers/generalSlice";
import {useNavigate} from "react-router-dom";

const VerifyPasswordRecover = ({uEmail}) => {
    const navigate = useNavigate()
    const [profilePic, setProfilePic] = useState('')
    const [value, setValue] = useState('')
    const dispatch = useDispatch()
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [nextStep, setNextStep] = useState(false)

    const [verifyPasswordRecover, isVerifyPasswordRecoverLoading, verifyPasswordRecoverError] = useFetching(async () => {
        const response = await authApi.verifyPasswordRecover({
            'char': value,
            'email': uEmail,
            'password': password1
        })
        if (response.status === 200) {
            navigate('/login')
        }
        else if (response.status === 406){
            navigate('/recover-password')
        }
    })
    const changePassword = () => {
        if (password1 === password2) {
            verifyPasswordRecover()
        }
        else {
            dispatch(setMessageType('error'))
            dispatch(setMessage('Passwords do not match'))
        }
    }
    return (

        <div className={classes.auth_container}>
            <div style={{
                fontSize: '35px',
                fontWeight: 'bold',
            }}>
                <label htmlFor="six-digit-input">{!nextStep
                    ?<>Verify Person</>
                    :<>Recover Your Password</>
                }</label>
            </div>
            {!nextStep
            ?<div>
                <DigitBoxForm setChar={setValue}></DigitBoxForm>
            </div>
            :<div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 10
                }}>
                    <input type="text" className={classes.login_input} placeholder='Input Your New Password' onChange={(e) => {setPassword1(e.target.value)}}/>
                    <input type="password" className={classes.login_input} placeholder='Retype Your New Password' onChange={(e) => {setPassword2(e.target.value)}}/>
                </div>
            }
            {!nextStep
            ?<div className={classes.buttons_log+" "+classes.blue} style={{
                width: '65%'
            }} onClick={() => {setNextStep(true)}}>
                    <div><FontAwesomeIcon icon={faUserCheck} /></div>
                    <div style={{fontWeight: 'bold'}}>Next</div>
                    <div></div>
                </div>
            :<div className={classes.buttons_log+" "+classes.blue} style={{
                width: '65%'
            }} onClick={changePassword}>
                <div><FontAwesomeIcon icon={faUnlock} /></div>
                <div style={{fontWeight: 'bold'}}>Change Password</div>
                <div></div>
            </div>
            }

        </div>
    );
};

export default VerifyPasswordRecover;