import React, {useEffect, useRef, useState} from 'react';
import classes from "../../Auth/Oauth/styles.module.css";

const DigitBoxForm = ({setChar}) => {
    const [value, setValue] = useState(['', '', '', '', '', '']);
    const inputs = useRef([]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        const newValueArray = newValue.split('').slice(0, 6);
        const paddedValueArray = [...newValueArray, ...Array(6 - newValueArray.length).fill('')];
        setValue(paddedValueArray);
    }
    const handleDigitChange = (index, event) => {
        const newValue = event.target.value;
        const newValueArray = [...value];
        newValueArray[index] = newValue.slice(-1);
        setValue(newValueArray);

        if (newValueArray[index].length === 1 && inputs.current[index + 1]) {
            inputs.current[index + 1].focus();
        } else if (newValueArray[index].length === 0 && inputs.current[index - 1]) {
            inputs.current[index - 1].focus();
        }
    }
    useEffect(() => {
        const handlePaste = (event) => {
            const pastedValue = event.clipboardData.getData('text');
            const pastedValueArray = pastedValue.split('').slice(0, 6);
            const paddedValueArray = [...pastedValueArray, ...Array(6 - pastedValueArray.length).fill('')];
            setValue(paddedValueArray);
        }

        document.addEventListener('paste', handlePaste);

        return () => {
            document.removeEventListener('paste', handlePaste);
        }
    }, []);
    useEffect(() => {
        setChar(value.join(''))
    }, [value])
    return (
        <div className={classes.sixDigitInputContainer}>
            {value.map((digit, index) => (
                <input
                    key={index}
                    className={classes.digitInput}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(event) => handleDigitChange(index, event)}
                    ref={(el) => (inputs.current[index] = el)}
                />
            ))}
            <input
                type="text"
                className={classes.dummyInput}
                value={value.join('')}
                onChange={handleChange}
            />
        </div>
    );
};

export default DigitBoxForm;