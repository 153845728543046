import React, {useEffect, useState} from 'react';
import {faCameraRetro, faFloppyDisk, faPlay, faRotateRight, faStop} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PhotoUpload from "../../General/Forms/PhotoUpload";
import {useSelector} from "react-redux";
import classes from './AdminBotUpdate.module.css'
import SimpleLineChart from "../../General/Chart/SimpleLineChart";
import {monthNames} from "../../../constants";
import PieChart from "../../General/Chart/PieChart";
import {useFetching} from "../../../hooks/useFetching";
import profileApi from "../../../api/profileApi";
import {setMessage, setMessageType} from "../../../reducers/generalSlice";
import adminApi from "../../../api/adminApi";

const AdminBotUpdate = () => {
    const selectedBot = useSelector(state => state.admin.adminSelectedBot)
    const [selectedImageBot, setSelectedFileBot] = useState(null);
    const [botStatus, setBotStatus] = useState(selectedBot.status)
    const [allUserList, setAllUserList] = useState([])
    const [saveData, setSaveData] = useState({
        "full_name": selectedBot.full_name,
        "username": selectedBot.username,
        "bot_token": selectedBot.bot_token,
        "user": selectedBot.user.id,
        "visibility": selectedBot.visibility,
    })
    const [userStats, setUserStats] = useState(selectedBot.bot_stats.daily_users.users_stats)
    const [userActivity, setUserActivity] = useState(selectedBot.bot_stats.daily_activity)

    useEffect(() => {
        setUserStats(prevUserStats => {
            if (prevUserStats.length < 30) {
                const paddedArray = Array(30 - prevUserStats.length).fill(0);
                return [...paddedArray, ...prevUserStats];
            }
            return prevUserStats.slice(-30);
        });

        setUserActivity(prevUserActivity => {
            if (prevUserActivity.length < 30) {
                const paddedArray = Array(30 - prevUserActivity.length).fill(0);
                return [...paddedArray, ...prevUserActivity];
            }
            return prevUserActivity.slice(-30);
        });
    }, []);

    let lastMonthDate = []
    for (let i =0;i<30;i++){
        let date = new Date();
        date.setDate(date.getDate() - i);
        let formDate = date.getDate()+' '+monthNames[date.getMonth()].slice(0, 3).toLowerCase()
        lastMonthDate.push(formDate)
    }
    const [lineChartData, setLineChartData] = useState({
        datasets: []
    });

    useEffect(() => {
        setLineChartData({
            labels: lastMonthDate.reverse(),
            datasets: [
                {
                    label: "Users",
                    data: userStats,
                    backgroundColor: [
                        "#E2FBD7"
                    ],
                    borderColor: "#34B53A",
                    borderWidth: 4,
                    pointRadius: 1,
                    fill: true,

                },
                {
                    label: "Activity",
                    data: userActivity,
                    backgroundColor: [
                        "#DAD7FE"
                    ],
                    borderColor: "#4339F2",
                    borderWidth: 4,
                    pointRadius: 1,
                    fill: true,

                }
            ]
        })
    }, [userStats, userActivity])

    const handleSelectedFileBot = (file) => {
        setSelectedFileBot(file);
    };
    const [stopBot, isStopBotLoading, stopBotError] = useFetching(async () => {
        const response = await profileApi.StopBot(selectedBot.id)
        setBotStatus("stop")
    })
    const [runBot, isRunBotLoading, runBotError] = useFetching(async () => {
        const response = await profileApi.RunBot(selectedBot.id)
        setBotStatus("run")
    })
    const [restartBot, isRestartBotLoading, restartBotError] = useFetching(async () => {
        const response = await profileApi.RestartBot(selectedBot.id)
    })
    const [getAllUsersAdmin, isGetAllUsersAdmin, getAllUsersAdminError] = useFetching(async () => {
        const response = await adminApi.GetAllUsersAdmin()
        setAllUserList(response.data)
    })
    const [saveBot, isSaveBotLoading, saveBotError] = useFetching(async () => {
        const data = new FormData();
        data.append('profile_pic', selectedImageBot);
        data.append('full_name', saveData.full_name);
        data.append('username', saveData.username);
        data.append('bot_token', saveData.bot_token);
        data.append('user', saveData.user);
        data.append('visibility', saveData.visibility);
        const response = await adminApi.UpdateBotAdmin(selectedBot.id, data)
    })

    useEffect(() => {
        getAllUsersAdmin()
    }, [])
    return (
        <div style={{
            padding: 15,
            display: 'flex',
            flexDirection: 'column',
            gap: 10
        }}>
            <div style={{display:'flex', gap: 15}}>
                <PhotoUpload defImage={selectedBot.profile_pic} onFileSelect={handleSelectedFileBot}></PhotoUpload>
                <div style={{display:'flex', flexDirection: 'column', gap:5, width: '100%', justifyContent: 'center'}}>
                    <div style={{
                        display: 'flex'
                    }}>
                        <input type="text" placeholder={'full_name'} className={classes.input_forms} value={saveData.full_name} onChange={(event) => {
                            setSaveData((prevState) => ({
                                ...saveData,
                                full_name: event.target.value,
                            }));
                        }}/>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <input type="text" placeholder={'username'} className={classes.input_forms} value={saveData.username} onChange={(event) => {
                            setSaveData((prevState) => ({
                                ...saveData,
                                username: event.target.value,
                            }));
                        }}/>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', display: "flex"}}>
                <input type="text" placeholder={'bot token'} className={classes.input_forms} value={saveData.bot_token} onChange={(event) => {
                    setSaveData((prevState) => ({
                        ...saveData,
                        bot_token: event.target.value,
                    }));
                }}/>
            </div>
            <div style={{display:'flex', gap: 15}}>
                <div style={{
                    width: '100%'
                }}>
                    <select name="user" className={classes.select_form} onChange={(event) => {
                        setSaveData((prevState) => ({
                            ...saveData,
                            user: event.target,
                        }));
                    }}>
                        {allUserList.map((user, index) =>
                            <option value={user.id} selected={selectedBot.user.id === user.id}>{user.email}</option>
                        )}
                    </select>
                </div>
                <div style={{
                    width: '100%'
                }}>
                    <select name="visibility" className={classes.select_form} onChange={(event) => {
                        setSaveData((prevState) => ({
                            ...saveData,
                            visibility: event.target.value,
                        }));
                    }}>
                        <option value="public" selected>Public</option>
                        <option value="private">Private</option>
                    </select>
                </div>
            </div>
            <div className={classes.admin_card} style={{
                display: 'flex',
                height: '540px',
            }}>
                <SimpleLineChart chartData={lineChartData} maxValue={selectedBot.highest_user}></SimpleLineChart>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'end',
                gap: 10,
            }}>
                {botStatus === 'stop'
                    ?<div onClick={runBot} className="buttons" style={{
                        width: '40px',
                        height: '40px'
                    }}>
                        {isRunBotLoading
                            ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                            :<FontAwesomeIcon icon={faPlay}/>
                        }
                    </div>
                    :[
                        <div className="buttons" onClick={stopBot} style={{
                            width: '40px',
                            height: '40px'
                        }} >
                            <FontAwesomeIcon icon={faStop} />
                        </div>,
                        <div onClick={restartBot} className="buttons" style={{
                            width: '40px',
                            height: '40px'
                        }}>
                            {isRestartBotLoading
                                ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                                :<FontAwesomeIcon icon={faRotateRight}/>
                            }
                        </div>

                    ]}
                <div className="buttons" onClick={saveBot} style={{
                    width: '40px',
                    height: '40px'
                }}>
                    <FontAwesomeIcon icon={faFloppyDisk} />
                </div>
            </div>
        </div>
    );
};

export default AdminBotUpdate;