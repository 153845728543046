import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setMessage, setMessageType} from "../../../../reducers/generalSlice";
import {Handle, Position} from "reactflow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareRss, faXmark} from "@fortawesome/free-solid-svg-icons";

const RssNode = (node) => {
    const [rssLink, setRssUrl] = useState(String(node.data.rssUrl))
    useEffect(() => {
        node.data.rssUrl = rssLink
    }, [rssLink])

    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }

    return (
        <div className='node rss_node' style={{width: 200}}>
            <div><span style={{fontSize:"15px",fontWeight:"bold", display:'flex', gap: 5, alignItems: 'center'}}>RSS<FontAwesomeIcon icon={faSquareRss} /></span></div>
            <span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
            </span>
            <div style={{display:"flex", marginTop: 10}}>
                <input type="text" className='button_name nodrag' placeholder="RSS url"
                       onChange={e => setRssUrl(e.target.value)} value={rssLink} />
            </div>
        </div>
    );
};

export default RssNode;