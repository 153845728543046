import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useFetching} from "../../../hooks/useFetching";
import adminApi from "../../../api/adminApi";
import SortableTableBotList from "../AdminBotList/SortableTable/SortableTableBotList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import SortableTableUserList from "./SortableTable/SortableTableUserList";

const AdminUserList = () => {
    const [currentPage, setPage] = useState(1);
    const adminBotListSort = useSelector(state => state.admin.adminUserListSort)
    const adminBotListSearch = useSelector(state => state.admin.adminUserListSearch)
    const [botList, setBotList] = useState([]);
    const [pageJson, setPageJson] = useState({
        'prevPage' : '',
        'nextPage': ''
    })

    const [getAdminUserList, isGetAdminUserListLoading, getAdminUserListError] = useFetching(async () => {
        const response = await adminApi.GetAdminProfileUsers(currentPage, adminBotListSort, adminBotListSearch);
        setBotList(response.data.results);
        setPageJson({
            'prevPage': response.data.previous,
            'nextPage': response.data.next
        })
    });

    useEffect(() => {
        getAdminUserList();
    }, [currentPage, adminBotListSort, adminBotListSearch]);

    return (
        <div style={{
            display: 'flex',
            padding: '15px 20px',
            backgroundColor: 'rgb(255 255 255 / 25%)',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            border: '1px solid #bbbbbb3b',
            borderTop: 'none',
            flexDirection: 'column',
        }}>
            <SortableTableUserList botList={botList}></SortableTableUserList>
            <div style={{
                display: 'flex',
                gap: '10px',
                marginTop: '15px',
                width: '100%',
                justifyContent: 'end'
            }}>
                <div onClick={() => setPage(currentPage-1)} style={{
                    fontSize: 20,
                    width: '35px',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    border: '1px solid #80808038',
                    pointerEvents: pageJson.prevPage===null?'none':'auto',
                }}><FontAwesomeIcon icon={faAngleLeft} /></div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 20,
                    fontWeight: 'bold'
                }}>
                    {currentPage}
                </div>
                <div onClick={() => setPage(currentPage+1)} style={{
                    fontSize: 20,
                    width: '35px',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    border: '1px solid #80808038',
                    pointerEvents: pageJson.nextPage===null?'none':'auto',
                }}><FontAwesomeIcon icon={faChevronRight} /></div>
            </div>
        </div>

    );
};

export default AdminUserList;