import {apiPath} from '../constants';
import axios from "axios";

export default class userApi {
    static async UpdateUserProfile(data) {
        return await axios.put(`${apiPath}/users/update-profile/`, data,{
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async VerifyNewEmail(data) {
        return await axios.post(`${apiPath}/users/verify-new-email/`, data,{
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async SearchUser({query}) {
        return await axios.get(`${apiPath}/users/search?search=${query}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }
}