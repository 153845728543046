import React, {useEffect, useState} from 'react';
import {Button, TextField} from "@mui/material";
import {useSelector} from "react-redux";
import {useFetching} from "../../hooks/useFetching";
import profileApi from "../../api/profileApi";
import {setBotCount, setBots} from "../../reducers/profileSlice";
import UserEditForm from "../../components/Settings/UserEditForm";

const UserSettings = () => {
    const user = useSelector((state) => state.auth.user)
    const [openAiToken, setOpenAIToken] = useState(user.openAI_token)
    const [updateProfile, isUpdateProfileLoading, addUpdateProfileError] = useFetching(async () => {
            const response = await profileApi.UpdateUserProfile({
                'openAI_token': openAiToken
            })
    })
    useEffect(() => updateProfile, [openAiToken])
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40%',
            }}>
                <UserEditForm></UserEditForm>
            </div>
        </div>
    );
};

export default UserSettings;