import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCameraRetro} from "@fortawesome/free-solid-svg-icons";

const PhotoUpload = ({onFileSelect, defImage}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(defImage);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!allowedTypes.includes(file.type)) {
            alert('Only PNG, JPEG, and JPG files are allowed.');
            return;
        }

        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);

        onFileSelect(file);
    };

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <div>
            <input
                id="fileInput"
                type="file"
                accept=".png, .jpeg, .jpg"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            {!previewUrl ? (
                <div onClick={handleImageClick} style={{
                    backgroundColor: '#d7d5d5b8',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 15,
                    boxShadow: '0px 0px 20px 0px #c9c9c933',

                }}>
                    <FontAwesomeIcon
                        icon={faCameraRetro}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                ):<div>
                    <img src={previewUrl} alt="Preview" style={{ width: 100,
                        height: 100,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 15,
                        boxShadow: '0px 0px 20px 0px #c9c9c933',
                    objectFit: 'cover'}} onClick={handleImageClick}/>
                </div>
            }

        </div>
    );
};

export default PhotoUpload;