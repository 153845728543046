import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {apiPath} from "../../../constants";
import ProfileImg from "../ProfileImg/profileImg";
import classes from "./styles.module.css";
import {Link, useNavigate} from "react-router-dom";
import {removeToken} from "../../../reducers/authSlice";
import {setShowSidebar} from "../../../reducers/generalSlice";
import {useSpring, animated} from 'react-spring'
import {deleteCookie} from "../../../helpers/helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faBell} from "@fortawesome/free-solid-svg-icons";
import {Avatar} from "@mui/material";
import Messages from "./Messages";

const Header = () => {
    const navigate = useNavigate()
    const showSidebar = useSelector(state => state.general.showSidebar)
    const user = useSelector((state) => state.auth.user)
    const selectedBot = useSelector((state) => state.profile.selectedBot)
    const dispatch = useDispatch()
    const [popoverActive, setPopoverActive] = useState(false)
    const [props, set, stop] = useSpring(() => ({opacity: 0, right:0, display:'none'}))
    const [showSelectedBot, setShowSelectedBot] = useState(false)
    const [showChangeButton, setShowChangeButton] = useState(false)

    useEffect(() => {
        set({right:popoverActive?25:0, opacity: popoverActive ?1:0, display:popoverActive?'flex':"none"})
    }, [popoverActive])
    useEffect(() => {
        if(window.innerWidth < 576){
            setShowChangeButton(true)
        }
    }, [])
    return (
        <div style={{
            width: '100%',
            fontSize: '20px',
        }}>
            <div style={{
                padding:'15px 0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <h1 style={{
                    fontSize: '25px',
                    fontWeight: 'bold',
                    display: "flex",
                    gap: 20,
                    alignItems: "baseline",
                }}>
                    {showChangeButton===true
                    ?<span className={classes.sidebarButt} onClick={() => {
                        dispatch(setShowSidebar(!showSidebar))}}>
                        <FontAwesomeIcon icon={faBars} />
                    </span>
                    :<></>
                    }
                    Buildo
                </h1>
                {showSelectedBot
                ?<div>
                    @{selectedBot.username}
                </div>
                :<div></div>
                }
                <div style={{
                    display:'flex',
                    gap: 25,
                    alignItems: 'center',
                }}>
                    <Messages></Messages>
                    <div onClick={() => setPopoverActive(!popoverActive)}>
                        <Avatar alt={user.full_name} src={apiPath+user.profile_pic} />
                    </div>
                </div>
            </div>
            <animated.div className={classes.Popover} style={props}>
                <Link to={'/'}>
                    <div className={classes.button_popover}>Dashboard</div>
                </Link>
                <Link to={'/admin-panel'}>
                    <div className={classes.button_popover}>Admin</div>
                </Link>
                <div className={classes.hr}></div>
                <div className={classes.button_popover} onClick={()=> {
                    dispatch(removeToken())
                    deleteCookie('token')
                    navigate('/')
                }}>Log Out</div>
                <div className={classes.hr}></div>
                <div className={classes.button_popover}>
                    <Link to={'/plan'}>
                        <div className={classes.SignUp}>Upgrade to Pro</div>
                    </Link>
                </div>
            </animated.div>
        </div>
    );
};

export default Header;