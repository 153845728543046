import React, {useEffect, useState} from 'react';
import "../../styles/Flow.css"
import {defaultUrl} from "../../constants";
import {faCircleQuestion, faSquareRss} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const Sidebar = () => {
    let bots = useSelector((state) => state.profile.bots)
    let urlParam = useParams().slug
    const selectedBot = bots.find(bot => bot.username === urlParam);
    console.log(bots)
    console.log(selectedBot)

    const onDragStart = (event, typeB) => {
        typeB = JSON.stringify(typeB);
        event.dataTransfer.setData('block', typeB);
        event.dataTransfer.effectAllowed = 'move';
    };
    const [blockStore, setBlockStore] = useState(selectedBot.blueprint.block_list)
    return (
        <div className='flowArea_Navbar'>

                {blockStore.map((block, index) =>
                    <div
                        className="Node_prefab"
                        draggable
                        aria-grabbed='true'
                        onDragStart={(event) => onDragStart(event, block)}
                        onTouchStart={(event) => onDragStart(event, block)}
                        key={index}
                        style={{
                            backgroundColor: block.color
                        }}
                    >
                        <div style={{
                            fontWeight: 'bold',
                            fontSize: '19px',
                            color: '#1a192b',
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: 'baseline'
                        }}><span>{block.label}</span><span style={{color:"rgba(58,58,58,0.35)"}}><FontAwesomeIcon icon={faCircleQuestion} /></span></div>
                        <p style={{
                            fontSize: '14px',
                            color: 'rgb(26 25 43 / 60%)'
                        }}>{block.desc}</p>
                        <div style={{
                            display: 'flex',
                            gap: 5,
                        }}>
                            {block.args.map((arg, index) =>
                                <div key={index} style={{
                                    backgroundColor: '#ffffff78',
                                    padding: '5px 15px',
                                    borderRadius: '10px',
                                    color: 'rgb(26 25 43 / 60%)',
                                    cursor: 'pointer',
                                }}>{arg}</div>
                            )}
                        </div>
                    </div>
                )}
        </div>
    );
};

export default Sidebar;
