import React, {useEffect, useState} from 'react';


const Icon = ({children}) => {
    const [backgroundColor, setBackgroundColor] = useState('');
    const [textColor, setTextColor] = useState('');

    useEffect(() => {
        generateRandomColor();
    }, []);

    const generateRandomColor = () => {
        const colors = ['red', 'yellow', 'blue', 'green', 'pink', 'purple', 'gray', 'orange'];
        const randomColor = getRandomUniqueColor(colors);
        setBackgroundColor(getColorWithOpacity(randomColor, 0.3));
        setTextColor(getDarkerColor(randomColor));
    };

    const getRandomUniqueColor = (colors) => {
        if (colors.length === 0) return '';

        const randomIndex = Math.floor(Math.random() * colors.length);
        const randomColor = colors[randomIndex];

        // Remove the selected color from the array to prevent duplicates
        colors.splice(randomIndex, 1);

        return randomColor;
    };

    const getColorWithOpacity = (color, opacity) => {
        const colorMap = {
            red: `rgba(243, 101, 74, ${opacity})`,
            yellow: `rgba(255, 181, 54, ${opacity})`,
            blue: `rgba(33, 126, 253, ${opacity})`,
            green: `rgba(26, 213, 152, ${opacity})`,
            pink: `rgba(219, 90, 238, ${opacity})`,
            purple: `rgba(137, 91, 241, ${opacity})`,
            gray: `rgba(61, 102, 149, ${opacity})`,
            orange: `rgba(255, 165, 0, ${opacity})`,
        };

        return colorMap[color] || '';
    };

    const getDarkerColor = (color) => {
        const darkerColor = {
            red: 'rgba(243, 101, 74, 1)',
            yellow: 'rgba(255, 181, 54, 1)',
            blue: 'rgba(33, 126, 253, 1)',
            green: 'rgba(26, 213, 152, 1)',
            pink: 'rgba(219, 90, 238, 1)',
            purple: 'rgba(137, 91, 241, 1)',
            gray: 'rgba(61, 102, 149, 1)',
            orange: 'rgba(128, 82, 0, 1)',
        };
        return darkerColor[color] || '';
    };

    const divStyle = {
        backgroundColor,
        color: textColor,
        height: '45px',
        borderRadius: '10px',
        width: '45px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        // border: `1px solid ${textColor}`
    };


    return (
        <div style={divStyle}>
            {children}
        </div>
    );
};

export default Icon;