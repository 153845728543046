import {apiPath} from '../constants';
import axios from "axios";

export default class profileApi {
    static async GetProfileBots() {
        return await axios.get(`${apiPath}/bot/list/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async AddNewBot(data) {
        return await axios.post(`${apiPath}/bot/create/`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async BotSetup(botID, data) {
        return await axios.post(`${apiPath}/bot/${botID}/setup/`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async RunBot(botID) {
        return await axios.get(`${apiPath}/bot/${botID}/run/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async RestartBot(botID) {
        return await axios.get(`${apiPath}/bot/${botID}/restart/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async StopBot(botID) {
        return await axios.get(`${apiPath}/bot/${botID}/stop/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async BotTerminalStatus(botID){
        return await axios.get(`${apiPath}/bot/${botID}/journalctl/`,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
    static async GetBlueprintsList(){
        return await axios.get(`${apiPath}/blueprint/list/`,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
    static async GenerateNewContributionInvitation(botId){
        return await axios.get(`${apiPath}/bot/${botId}/generate_contribute_char/`,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
    static async AddContributor(slug){
        return await axios.get(`${apiPath}/bot/add_contributor/${slug}/`,{
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
    static async BotSendContributionInvitation(data){
        return await axios.post(`${apiPath}/bot/send_invitation/`,data, {
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
    static async GetBotDetails(botID){
        return await axios.get(`${apiPath}/bot/${botID}/`, {
            headers: {
                'Content-Type': 'multipart/form-data; ',
            },
        })
    }
}