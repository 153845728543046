import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

function SimpleLineChart({ chartData, maxValue }) {
    return (
        <Line
            style={{
                width: '100%',
                height: '100%',
                position: 'absolute'
            }}
            data={chartData}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    // title: {
                    //     display: false,
                    // },
                    // legend: {
                    //     display: false
                    // },
                    tooltip: {
                        enabled: false
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            beginAtZero: false,
                            display: true,
                            stepSize: 10
                        },
                        grid: {
                            drawBorder: false,
                            display: false,
                        },
                        border:{
                            display: false
                        }
                    },
                    y: {
                        ticks: {
                            // display: false,
                            beginAtZero: true,
                            stepSize: Math.round(maxValue/4)
                        },
                        grid: {
                            drawBorder: false,
                            display: false,
                        },
                        border:{
                            display: false
                        }
                    },
                },
            }}
        />
    );
}
export default SimpleLineChart;