import {apiPath} from '../constants';
import axios from "axios";

export default class authApi {
    static async loginUser(data) {
        const response = await axios.post(`${apiPath}/app_auth/login/`, data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return [response.data, response.status]
    }
    static async getUser() {
        return await axios.get(`${apiPath}/app_auth/user/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }
    static async verifyEmail(data){
        return await axios.post(`${apiPath}/app_auth/email_verify/`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }
    static async refreshToken(data){
        return await axios.post(`${apiPath}/app_auth/refresh_token/`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }
    static async sendRecoverPassword(data){
        return await axios.post(`${apiPath}/app_auth/recover_password/`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }
    static async verifyPasswordRecover(data){
        return await axios.post(`${apiPath}/app_auth/verify_password_recover/`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }
}