import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge, MarkerType, ReactFlowProvider, useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import "../../styles/Flow.css"
import Sidebar from "./Sidebar";
import nodeTypes from "./Nodes/NodeTypes";
import edgeTypes from "./Edges/EdgeTypes";
import UI from "./UI";
import {useDispatch, useSelector} from "react-redux";
import {makeId} from "../../helpers/helper";
import {setMessage, setMessageType} from "../../reducers/generalSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";


const BotSetup = () => {
    const dispatch = useDispatch()
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const { setViewport } = useReactFlow();
    let bots = useSelector((state) => state.profile.bots)
    let urlParam = useParams().slug
    const selectedBot = bots.find(bot => bot.username === urlParam);
    console.log(selectedBot)
    const user = useSelector((state) => state.auth.user)


    const onRestore = useCallback(() => {
        const restoreFlow = async () => {
            let flow;
            if (Object.keys(selectedBot.file.interface).length === 0){
                flow = JSON.parse(JSON.stringify(selectedBot.blueprint.interface));
            }else {
                flow = JSON.parse(selectedBot.file.interface);
            }
            console.log(flow)
            const { x = 0, y = 0, zoom = 1 } = flow.viewport;
            for(let nod of flow.nodes){
                nod.data.setNodes = setNodes
            }
            for(let edg of flow.edges){
                edg.data.setEdges = setEdges
            }
            setNodes(flow.nodes || []);
            setEdges(flow.edges || []);
            setViewport({ x, y, zoom });
        };

        restoreFlow();
    }, [setNodes, setViewport]);

    useEffect(() => {
        onRestore()
    }, [])

    const onConnect = useCallback((connection) => {
        setEdges((eds) => addEdge({
            ...connection,
            markerEnd: {type: MarkerType.ArrowClosed},
            type: "buttonEdge",
            data: {setEdges:setEdges},
            animated: true,
        }, eds))
    }, []);

    const onDrop = useCallback((event) => {
        // event.preventDefault();

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const block = JSON.parse(event.dataTransfer.getData("block"));


        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        let Ndata;
        if(block.type === 'commandNode'){
            Ndata = {"deleteAble":block.deleteAble,"editAble":block.editAble, value:block.value[Math.floor(Math.random() * block.value.length)], isMain: true, setNodes:setNodes}
        }else if(block.type === 'postNode'){
            Ndata = {postImg: '', postTitle: "", postDescription: "", isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'fileNode'){
            Ndata = {postFile: "", postDescription: "", isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'messageByIdNode'){
            Ndata = {value: block.value, chatId: "", isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'postImportNode'){
            Ndata = {postFile: "", postDescription: "", isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'urlNode'){
            Ndata = {label: "", url: "", isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'openAIChat'){
            Ndata = {knowledge: "", isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'apiNode'){
            Ndata = {siteUrl: "", useAuthToken:false, inputFields:[''], jsonObj:{}, authToken:"", amountJson:0, method:"get", isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'pollNode'){
            Ndata = {question:'', questionsFields:[], jsonObj:{}, isAnonymous: true, isQuiz: false, isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'rssNode'){
            Ndata = {rssUrl: '', isMain: block.isMain, setNodes:setNodes}
        }else if(block.type === 'channelSettingsNode'){
            Ndata = {channelUsername: '', adminUserId:'', isMain: block.isMain, setNodes:setNodes}
        } else{
            Ndata = {value: block.value, isMain: block.isMain, setNodes:setNodes}
        }
        if (block.type === 'openAIChat' && user.openAI_token === ''){
            dispatch(setMessage('OpenAi token is missing||Please insert your openAi token in settings.'))
            dispatch(setMessageType('error'))
        }else{
            setNodes((nds) => nds.concat({
                id: String(makeId(10)),
                position: position,
                data: Ndata,
                type: block.type,
            }))
        }

    },[reactFlowInstance]);
    return (
        <div
            style={{
                // width: window.innerWidth,
                height:window.innerHeight-125
        }}
            className="flowArea"
        >
            <UI edges={edges} nodes={nodes} reactFlowInstance={reactFlowInstance}></UI>
            <div className="flowArea_Work" ref={reactFlowWrapper}>
                <div className='flowCard'>
                    <ReactFlowProvider>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onConnect={onConnect}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onInit={setReactFlowInstance}
                            edgeTypes={edgeTypes}
                            nodeTypes={nodeTypes}
                            onDrop={onDrop}
                            onDragOver={(e) => {
                                e.preventDefault();
                                e.dataTransfer.dropEffect = 'move';
                            }}
                            className="touchdevice-flow"
                            fitView
                        >
                            <MiniMap/>
                            <Controls/>
                            <Background/>
                        </ReactFlow>
                    </ReactFlowProvider>
                </div>
                <Sidebar></Sidebar>
            </div>
            <div className='notAvailableForMobile'>
                <FontAwesomeIcon icon={faTriangleExclamation} />
                <div>
                    Not available for mobile devices
                </div>
            </div>
        </div>
    );
}

export default () => (
    <ReactFlowProvider>
        <BotSetup />
    </ReactFlowProvider>
);
