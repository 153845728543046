import React, {useEffect, useState} from 'react';
import {Avatar} from "@mui/material";
import {apiPath} from "../../../../constants";
import ProfileImg from "../../../General/ProfileImg/profileImg";
import classes from '../../AdminBotList/SortableTable/sortableTable.module.css'
import Icon from "./Icon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort, faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {setAdminBotListSort, setAdminUserListSort} from "../../../../reducers/adminSlice";

const SortableTableUserList = ({botList}) => {
    const dispatch = useDispatch()
    const adminUserListSort = useSelector(state => state.admin.adminUserListSort)
    const [data, setData] = useState(botList);

    const getSort = (param) => {
        const currentSort = adminUserListSort;

        if (currentSort === param) {
            dispatch(setAdminUserListSort(`-${param}`));
        } else if (currentSort === `-${param}`) {
            dispatch(setAdminUserListSort(''));
        } else {
            dispatch(setAdminUserListSort(param));
        }
        console.log(param)
    };

    useEffect(() => {
        setData(botList);
    }, [botList]);


    return (
        <table className={classes.table}>
            <thead>
                <tr>
                    <th></th>
                    <th className={classes.title} onClick={() => getSort('id')}>
                        <span style={{marginRight: 5}}>Email</span>
                        <span><FontAwesomeIcon icon={faSort} /></span></th>
                    <th className={classes.title}>
                        <span style={{marginRight: 5}}>Amount Of Bots</span></th>
                    <th className={classes.title} onClick={() => getSort('is_active')}>
                        <span style={{marginRight: 5}}>Is Active</span>
                        <span><FontAwesomeIcon icon={faSort} /></span></th>
                    <th className={classes.title} onClick={() => getSort('is_superuser')}>
                        <span style={{marginRight: 5}}>Is Superuser</span>
                        <span><FontAwesomeIcon icon={faSort} /></span></th>
                </tr>
            </thead>
            <tbody className={classes.body}>
            {data.map((item) => (
                <tr key={item.id} className={classes.tr}>
                    <td><input type="checkbox" name={'adminListSelect'}/></td>
                    <td style={{display: 'flex', alignItems:'center', gap: '10px'}}>
                        <span className={classes.icon}><Icon children={item.id}></Icon></span> <span style={{fontWeight: 'bold'}}>{item.email}</span>
                    </td>
                    <td><div style={{
                        color: 'grey',
                        border: '1px solid gainsboro',
                        padding: '10px 20px',
                        borderRadius: '15px',
                        width: 'min-content'

                    }}>{item.amount_user_bots}</div></td>
                    <td><div style={{
                        backgroundColor: item.is_active?'rgba(26, 213, 152, 0.3)':'rgba(243, 101, 74, 0.3)',
                        color: item.is_active?'rgba(26, 213, 152, 1)':'rgba(243, 101, 74, 1)',
                        width: 'min-content',
                        display: 'flex',
                        padding: '10px 25px',
                        borderRadius: '10px',
                    }}>{item.is_active?'True':'False'}</div></td>
                    <td><div style={{
                        backgroundColor: item.is_superuser?'rgba(26, 213, 152, 0.3)':'rgba(243, 101, 74, 0.3)',
                        color: item.is_superuser?'rgba(26, 213, 152, 1)':'rgba(243, 101, 74, 1)',
                        width: 'min-content',
                        display: 'flex',
                        padding: '10px 25px',
                        borderRadius: '10px',
                    }}>{item.is_superuser?'True':'False'}</div></td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default SortableTableUserList;