import {apiPath} from '../constants';
import axios from "axios";

export default class adminApi {
    static async GetAdminProfileBots(currentPage, adminBotListSort, adminBotListSearch) {
        const queryParams = new URLSearchParams();

        if (currentPage > 1) {
            queryParams.append('page', currentPage);
        }

        if (adminBotListSort !== '') {
            queryParams.append('order_by', adminBotListSort);
        }

        if (adminBotListSearch !== '') {
            queryParams.append('search', adminBotListSearch);
        }

        const queryString = queryParams.toString();

        return await axios.get(`${apiPath}/admin_panel/bot/list${queryString ? '?' + queryString : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async GetAdminProfileUsers(currentPage, adminBotListSort, adminBotListSearch) {
        const queryParams = new URLSearchParams();

        if (currentPage > 1) {
            queryParams.append('page', currentPage);
        }

        if (adminBotListSort !== '') {
            queryParams.append('order_by', adminBotListSort);
        }

        if (adminBotListSearch !== '') {
            queryParams.append('search', adminBotListSearch);
        }

        const queryString = queryParams.toString();

        return await axios.get(`${apiPath}/admin_panel/user/list${queryString ? '?' + queryString : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async GetAllUsersAdmin() {
        return await axios.get(`${apiPath}/admin_panel/user/list/all/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static async DeleteBotAdmin(bot_id) {
        return await axios.delete(`${apiPath}/admin_panel/bot/${bot_id}/`, {
            headers: {
                'Content-Type': 'multipart/form-data; ',
            }
        });
    }
    static async UpdateBotAdmin(bot_id, data) {
        return await axios.put(`${apiPath}/admin_panel/bot/${bot_id}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data; ',
            }
        });
    }
}