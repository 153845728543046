import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setMessage, setMessageType} from "../../../../reducers/generalSlice";
import {Handle, Position} from "reactflow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const MessageByIdNode = (node) => {
    const [messageValue, setMessageValue] = useState(String(node.data.value))
    const [chatId, setChatId] = useState(String(node.data.chatId))
    const dispatch = useDispatch()

    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }
    const IsValid = (connection) => {
        if(connection.targetHandle === 'buttons'){
            return true
        }
        else{
            dispatch(setMessageType('warning'))
            dispatch(setMessage('This edge work only with button node.'))
            return false
        }
    }
    const addChipText = (text) => {
        setMessageValue(messageValue+text);
    }
    useEffect(() => {
        node.data.value = messageValue
        node.data.chatId = chatId
    }, [messageValue, chatId])
    return (
        <div className='node message_node_by_id message_node'>
            <Handle type="target" position={Position.Top} id="message_node" isValidConnection={(connection) => {return connection.targetHandle !== 'apiNode'}}/>
            <div className="title">Send message id</div>
            <span className="close_butt" onClick={deleteNode}>
            <FontAwesomeIcon icon={faXmark} />
        </span>
            <div className='message_txtarea'>
                <textarea className='nodrag' name="message" draggable={false} onChange={e => setMessageValue(e.target.value)} value={messageValue}></textarea>
            </div>
            <div className='message_input'>
                <input className='nodrag' name="message" draggable={false} onChange={e => setChatId(e.target.value)} value={chatId} placeholder={'Insert user id, channel or group @username'}/>
            </div>
            <div className="chips_row">
                <div className="chip" onClick={() => {addChipText('{First name}')}}>User first name</div>
                <div className="chip" onClick={() => {addChipText('{Last name}')}}>User last name</div>
                <div className="chip" onClick={() => {addChipText('{User id}')}}>User id</div>
                <div className="chip" onClick={() => {addChipText('{Username}')}}>User username</div>
            </div>
            <Handle type="source" className="buttons_node" id="buttons" position={Position.Bottom} isValidConnection={IsValid}/>
        </div>
    );
}

export default MessageByIdNode;