import { Handle, Position } from 'reactflow';
import '../node.css'
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useFetching} from "../../../../hooks/useFetching";
import {useSelector} from "react-redux";
import {getHumanByte} from "../../../../helpers/helper";
import botStaticApi from "../../../../api/botStaticApi";

function FileNode(node) {
    const [messageValue, setMessageValue] = useState(String(node.data.postDescription))
    const [postFile, setPostFile] = useState(String(node.data.postFile))
    const selectedBot = useSelector((state) => state.profile.selectedBot)
    const [fileId, setFileId] = useState(null)

    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }

    const [savePostFile, isSavePostFileLoading, savePostFileError] = useFetching(async () => {
        const data = new FormData();
        data.append('bot', selectedBot.id);
        data.append('file', node.data.postFile);
        data.append('size', node.data.size_not);
        const response = await botStaticApi.UploadBotPostFile(data)
        setFileId(response.data.id)
        node.data.postFile = response.data.file
    })
    const [deletePostFile, isDeletePostFileLoading, deletePostFileError] = useFetching(async () => {
        const response = await botStaticApi.DeleteBotPostFile(fileId)
        setPostFile('')
    })
    useEffect(() => {
        node.data.postDescription = messageValue
    }, [messageValue])
    return (
        <div className='node file_node message_node'>
            <Handle type="target" position={Position.Top} id="message_node" isValidConnection={(connection) => {
                return connection.sourceHandle !== 'buttons' && connection.targetHandle !== 'apiNode'
            }}/>
            <div className="title">Send File</div>
            <span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
            </span>
            <div className='file_settings'>
                {postFile===''
                ?<div>
                    <label htmlFor={node.id+'file_import'}>
                        <div className="file_import">
                            <p>Please, add file</p>
                        </div>
                    </label>
                    <input type="file" id={node.id+'file_import'} onChange={(e) => {
                        setPostFile(e.target.files[0]);
                        node.data.postFile = e.target.files[0]
                        node.data.filename = e.target.files[0].name
                        node.data.size = getHumanByte(e.target.files[0].size)
                        node.data.size_not = e.target.files[0].size
                        savePostFile();
                    }} hidden/>
                </div>
                :<div>
                    <div className="file_preview">
                        <FontAwesomeIcon icon={faFile} />
                        <div style={{flexGrow: 4}}>
                            <p className="filename">{node.data.filename}</p>
                            <p className="size">{node.data.size}</p>
                        </div>
                        <span title='Delete Post Image' className='delete_img' onClick={deletePostFile}><FontAwesomeIcon icon={faTrash} /></span>
                    </div>
                    <div className="file_description">
                        <label htmlFor="file_description">File Caption(optional)</label>
                        <div className="mess">
                            <textarea className='nodrag' name="message" id="file_description" placeholder='File Caption(optional)' draggable={false} onChange={e => setMessageValue(e.target.value)} value={messageValue}></textarea>
                        </div>
                    </div>
                </div>
                }



            </div>
        </div>
    );
}

export default FileNode;