import React, {useEffect} from 'react';
import AdminBotList from "../../components/Admin/AdminBotList/AdminBotList";
import AdminBotListTopPanel from "../../components/Admin/AdminBotList/AdminBotListTopPanel";

const AdminPageBotList = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', borderRadius: '10px', boxShadow: '0px 0px 20px 0px #c9c9c933'}}>
            <AdminBotListTopPanel></AdminBotListTopPanel>
            <AdminBotList></AdminBotList>
        </div>
    );
};

export default AdminPageBotList;