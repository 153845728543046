import {apiPath} from '../constants';
import axios from "axios";

export default class paymentApi {
    static async CreatePayment(data) {
        return await axios.post(`${apiPath}/payment/create/`, data,{
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async GetStatusPayment(data){
        return await axios.get(`${apiPath}/payment/get_status/${data.uid}/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async GetApprovePayment(data){
        return await axios.get(`${apiPath}/payment/approve_payment/${data.uid}/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}