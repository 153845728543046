import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport, faFloppyDisk, faGear, faMinus, faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Handle, Position} from "reactflow";
import '../node.css'
import {setMessage, setMessageType} from "../../../../reducers/generalSlice";
import {useDispatch} from "react-redux";
import {makeId} from "../../../../helpers/helper";

const ApiNode = (node) => {
    const [isSelected, setIsSelected] = useState(node.data.isSelected)
    const [apiLink, setApiLink] = useState(node.data.siteUrl)
    const [method, setMethod] = useState(node.data.method)
    const [authToken, setAuthToken] = useState(node.data.authToken)
    const dispatch = useDispatch()
    const [jsonObj, setJsonObj] = useState(node.data.jsonObj);
    const [inputFields, setInputFields] = useState(node.data.inputFields);

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const newInputFields = [...inputFields];
        newInputFields[index][name] = value;
        setInputFields(newInputFields);
    };

    const handleAddFields = () => {
        setInputFields([...inputFields, { key: "", value: "" }]);
    };

    const handleRemoveClick = (index) => {
        const newInputFields = [...inputFields];
        newInputFields.splice(index, 1);
        setInputFields(newInputFields);
        const { [newInputFields[index].key]: _, ...newObj } = jsonObj;
        setJsonObj(newObj);
    };

    const handleCreateJson = () => {
        const newJsonObj = inputFields.reduce((json, { value }) => {
            if (value.trim()) {
                json[value.trim()] = value.trim();
            }
            return json;
        }, {});
        setJsonObj(newJsonObj);
    };

    useEffect(() => {
        handleCreateJson();
    }, [inputFields])


    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }
    const IsValid = (connection) => {
        if(connection.targetHandle === 'messageNode'){
            return true
        }
        else{
            dispatch(setMessageType('warning'))
            dispatch(setMessage('This edge work only with message node.'))
            return false
        }
    }
    useEffect(() => {
        node.data.jsonObj = jsonObj
        node.data.inputFields = inputFields
        node.data.isSelected = isSelected
        node.data.authToken = authToken
        node.data.siteUrl = apiLink
        node.data.method = method
    }, [isSelected, apiLink, method, inputFields])
    return (
        <div className='node api_node' style={{width: isSelected ? 400 : 100, height: isSelected ? 'auto' : 16}}>
            <Handle type="target" position={Position.Top} id="message_node"/>
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent:'space-between'
            }}>
                <span style={{fontSize:"15px",fontWeight:"bold"}}>API</span>
                <span className="settings" onClick={() => setIsSelected(!isSelected)}><FontAwesomeIcon icon={faGear} /></span>
            </div>
                <span className="close_butt" onClick={deleteNode}>
                    <FontAwesomeIcon icon={faXmark} />
                </span>
            <div className="node_settings" style={{opacity: isSelected ? 100 : 0, display: isSelected?'flex':'none'}}>
                <div style={{display: 'flex'}}>
                    <input className='nodrag' type="text" placeholder={'Insert Api Link'} value={apiLink}
                           onChange={event => setApiLink(event.target.value)}/>
                </div>
                <div style={{display: 'flex'}}>
                    <input className={"nodrag"} type="text" placeholder={'Auth Token(optional)'}
                        onChange={event => setAuthToken(event.target.value)}/>
                </div>
                <div>
                    <select className='nodrag' name="" id=""
                            onChange={event => setMethod(event.target.value)}>
                        <option value="get" selected={method==='get'}>Get</option>
                        <option value="post" selected={method==='post'}>Post</option>
                        <option value="update" selected={method==='update'}>Update</option>
                        <option value="delete" selected={method==='delete'}>Delete</option>
                    </select>
                </div>
                <div style={{fontSize:"15px",fontWeight:"bold"}}>JSON DATA</div>
                <div style={{display:'flex', gap:5, flexDirection:'column'}}>
                    {inputFields.map((inputField, index) => (
                        <div key={index} style={{ display: "flex", gap: 5 }}>
                            <input
                                type="text"
                                name="key"
                                placeholder="Json Key"
                                className="nodrag"
                                value={inputField.key}
                                onChange={(event) => handleInputChange(event, index)}
                            />
                            <input
                                type="text"
                                name="value"
                                placeholder="Json Value"
                                className="nodrag"
                                value={inputField.value}
                                onChange={(event) => handleInputChange(event, index)}
                            />
                            <div className="minus" onClick={() => handleRemoveClick(index)}>
                                <FontAwesomeIcon icon={faMinus} />
                            </div>
                        </div>
                    ))}
                    <div className={'plus nodrag'} onClick={handleAddFields}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                </div>
            </div>
            <Handle
                type="source"
                className='successApi'
                id={'successApi'}
                position={Position.Bottom}
                isValidConnection={IsValid}
            />
            <Handle
                type="source"
                id={'errorApi'}
                style={{
                    left: isSelected?'401px':'98px'
                }}
                className='errorApi'
                position={Position.Bottom}
                isValidConnection={IsValid}
            />
        </div>
    );
};

export default ApiNode;