import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import LoadingDots from "../../components/General/Loading/LoadingDots";
import {useFetching} from "../../hooks/useFetching";
import profileApi from "../../api/profileApi";
import {useDispatch} from "react-redux";
import {setSelectedBot} from "../../reducers/profileSlice";

const AddContributorPage = () => {
    const { slug } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [addContributor, isAddContributorLoading, addContributorError] = useFetching(async () => {
        const response = await profileApi.AddContributor(slug)
        if (response.status === 200){
            dispatch(setSelectedBot(response.data))
            navigate('/bot-details')
        }
        else{
            navigate('/')
        }
    })
    useEffect(() => {
        addContributor()
    }, []);
    return (
        <div>
            <LoadingDots></LoadingDots>
        </div>
    );
};

export default AddContributorPage;