import React, {useState} from 'react';

const HeaderCategories = () => {
    const favCategory = useState()
    return (
        <div>
            
        </div>
    );
};

export default HeaderCategories;