import React, {useEffect, useState} from 'react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import authApi from "../../../api/authApi";
import {useFetching} from "../../../hooks/useFetching";
import axios from "axios";
import {useDispatch} from "react-redux";
import {removeToken, setIsAuth, setPassword, setToken, setUser} from "../../../reducers/authSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import classes from "./styles.module.css";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import {Link, useNavigate} from 'react-router-dom';
import {deleteCookie, setCookie} from "../../../helpers/helper";
import {setMessage, setMessageType} from "../../../reducers/generalSlice";


const Oauth = () => {
    const clientId = '887897594904-5e832vubms881j2oofll7icjun0v54af.apps.googleusercontent.com';
    const [email, setEmail] = useState('')
    const [password, setLocalPassword] = useState('')
    const [isGoogleAuth, setIsGoogleAuth] = useState(false)
    const [profilePic, setProfilePic] = useState('')
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(setPassword(password))
    }, [password])

    const [loginUser, isLoginUserLoading, loginUserError] = useFetching(async () => {
        console.log(email, password)
        if (email !== '' && password !== '' && /.+@.+\.[A-Za-z]+$/.test(email)){
            const data = new FormData();
            data.append('email', email);
            data.append('password', password);
            data.append('profile_pic', profilePic);
            data.append('is_google_auth', isGoogleAuth);
            const [res_data, res_status] = await authApi.loginUser(data)
            if(res_status === 200){
                axios.defaults.headers.common['Authorization'] = `Bearer ${res_data.access}`;
                setCookie('token', res_data.refresh)
                dispatch(setToken(res_data.refresh))
                dispatch(setIsAuth(true))
                dispatch(setUser(res_data.user))
                navigate('/');
            }
            else if(res_status === 207){
                dispatch(setUser(res_data.user))
            }
            else{
                dispatch(setMessage('Email or password is not correct.'))
                dispatch(setMessageType('error'))
                dispatch(removeToken())
                deleteCookie('token')
                delete axios.defaults.headers.common["Authorization"];
            }
        } else {
            dispatch(setMessage('Value Error||Email or Password is wrong please check if everything is correct'))
            dispatch(setMessageType('error'))
        }
    })

    useEffect(() => {
        setEmail('')
        setPassword('')
        setProfilePic('')
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);
    const onSuccess = (res) => {
        setEmail(res.profileObj.email)
        setPassword(res.profileObj.googleId)
        setProfilePic(res.profileObj.imageUrl)
        console.log(res.profileObj)
        setIsGoogleAuth(true);
        loginUser()
    };
    const onFailure = (err) => {
        console.log('failed:', err);
    };
    return (
        <div className={classes.auth_container}>
            <div style={{
                fontSize: '35px',
                fontWeight: 'bold',
            }}>
                Login to Buildo
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
            }}>
                <input type="email"  className={classes.login_input} placeholder='Email Address' onChange={(e) => {setEmail(e.target.value)}}/>
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
            }}>
                <input type="password" className={classes.login_input} placeholder='Password' onChange={(e) => {setLocalPassword(e.target.value)}}/>
            </div>
            <div className={classes.buttons_log+" "+classes.blue} onClick={loginUser}>
                <div><FontAwesomeIcon icon={faEnvelope} /></div>
                <div>Continue with Email</div>
                <div></div>
            </div>
            <div style={{
                width: '100%',
                borderBottom: '0.5px solid rgb(229 229 229 / 80%)',
            }}></div>
            <GoogleLogin
                clientId={clientId}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
                render={renderProps => (
                    <div className={classes.buttons_log+" "+classes.black} onClick={renderProps.onClick}>
                        <div><FontAwesomeIcon icon={faGoogle} /></div>
                        <div>Continue with Google</div>
                        <div></div>
                    </div>
                )}
            />
            <Link to={'/recover-password'}>
                <div style={{color: 'var(--main-grey)', cursor:'pointer', fontSize: '13px'}}>
                    Forgot Password?
                </div>
            </Link>
        </div>
    );
};

export default Oauth;