import { createSlice } from '@reduxjs/toolkit';

export const generalSlice = createSlice({
    name:   'general',
    initialState: {
        message: '',
        messageType:   '',
        showSidebar: true,
        language: 'us',
    },
    reducers: {
        setMessageType: (state, action) => {
            state.messageType = action.payload;
        },
        clearMessageType: (state) => {
            state.messageType = '';
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        clearMessage: (state) => {
            state.message = '';
        },
        setShowSidebar: (state, action) => {
            state.showSidebar = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
    },
});

export const {
    setMessageType, clearMessageType, setMessage, clearMessage, setShowSidebar, setLanguage
} = generalSlice.actions;

export default generalSlice.reducer;