import React, {useCallback, useEffect, useState} from 'react';
import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    useEdgesState,
    useNodesState,
    useReactFlow
} from "reactflow";
import edgeTypes from "../../BotSetup/Edges/EdgeTypes";
import nodeTypes from "../../BotSetup/Nodes/NodeTypes";
import {useSelector} from "react-redux";
import styles from './botdetails.module.css'
import 'reactflow/dist/style.css';
import {Link} from "react-router-dom";


const FlowPreview = ({botFile, miniMap=true, controls=false, number}) => {
    const selectedBot = useSelector(state => state.profile.selectedBot)
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const { setViewport } = useReactFlow();

    const restoreFlow =  () => {
        let flow;
        if (Object.keys(botFile.interface).length === 0){
            flow = JSON.parse(JSON.stringify(selectedBot.blueprint.interface));
        }else {
            flow = JSON.parse(botFile.interface);
        }
        console.log(flow)
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
    };
    useEffect(() => {
        restoreFlow();
    }, [selectedBot])

    return (
        <div className={styles.FlowPreview}>
            <Link to={`/${selectedBot.username}/set-up`}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    edgeTypes={edgeTypes}
                    nodeTypes={nodeTypes}
                    onDragOver={(e) => {
                        e.preventDefault();
                        e.dataTransfer.dropEffect = 'move';
                    }}
                    className="touchdevice-flow"
                    id={number}
                    fitView
                >
                    {miniMap && (<MiniMap/>)}
                    {controls && (<Controls/>)}
                    <Background/>
                </ReactFlow>
            </Link>
        </div>
    );
};

export default FlowPreview;
