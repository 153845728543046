import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import paymentApi from "../../api/paymentApi";
import {setCookie} from "../../helpers/helper";
import LoadingDots from "../../components/General/Loading/LoadingDots";
import {useDispatch} from "react-redux";
import {setUser} from "../../reducers/authSlice";
import PaymentStatus from "../../components/Profile/PremiumPlan/PaymentStatus";

const OrderStatus = () => {
    const { slug } = useParams();
    const dispatch = useDispatch()
    const [payment, setPayment] = useState({})
    const [getStatusPayment, isGetStatusPaymentLoading, getStatusPaymentError] = useFetching(async () => {
        const response = await paymentApi.GetStatusPayment({
            "uid": slug,
        })
        if (response.status === 200){
            setPayment(response.data.payment)
            dispatch(setUser(response.data.user))
        }
    })
    useEffect(() => {
        getStatusPayment()
    }, [])
    return (
        <div>
            {!isGetStatusPaymentLoading
                ?<PaymentStatus payment={payment}></PaymentStatus>
                :<LoadingDots></LoadingDots>
            }
        </div>
    );
};

export default OrderStatus;