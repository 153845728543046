import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Handle, Position} from "reactflow";
import '../node.css'
import {useFetching} from "../../../../hooks/useFetching";
import profileApi from "../../../../api/profileApi";
import {useSelector} from "react-redux";
import {apiPath} from '../../../../constants';
import Helper from "../../../../helpers/helper";
import botStaticApi from "../../../../api/botStaticApi";


const ImageNode = (node) => {
    const [isSelected, setIsSelected] = useState(node.data.isSelected)
    const [postImg, setPostImg] = useState(String(node.data.postImg))
    const [postTitle, setPostTitle] = useState(String(node.data.postTitle))
    const [postDescription, setPostDescription] = useState(String(node.data.postDescription))
    const selectedBot = useSelector((state) => state.profile.selectedBot)
    const [imgId, setImgId] = useState(null)

    useEffect(() => {
        node.data.postTitle = postTitle
        node.data.postDescription = postDescription
    }, [postTitle, postDescription])
    useEffect(() => {
        node.data.isSelected = isSelected
    }, [isSelected])
    useEffect(() => {
        let http = new XMLHttpRequest();
        http.open('HEAD', postImg, false);
        try {
            http.send();
        }
        catch (e){
            setPostImg('');
        }
    }, [])
    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }

    const [savePostImg, isSavePostImgLoading, savePostImgError] = useFetching(async () => {
        const data = new FormData();
        data.append('bot', selectedBot.id);
        data.append('file', node.data.postImg);
        data.append('size', node.data.size_not);
        const response = await botStaticApi.UploadBotPostImg(data)
        setImgId(response.data.id)
        setPostImg(apiPath+response.data.file)
        console.log(apiPath+response.data.file)
        node.data.postImg = apiPath+response.data.file
    })
    const [deletePostImg, isDeletePostImgLoading, deletePostImgError] = useFetching(async () => {
        const response = await botStaticApi.DeleteBotPostImg(imgId)
        setPostImg('')
    })

    return (
        <div className='node posts_node' style={{width: isSelected ? 250 : 100}}>
            <Handle type="target" id="post_node" position={Position.Top} isValidConnection={(connection) => {return connection.targetHandle !== 'apiNode'}}/>
            <Handle type="source" className="buttons_node" id="buttons" position={Position.Bottom} isValidConnection={(connection) => {
                return connection.targetHandle === 'buttons'
            }}/>
            <div><span style={{fontSize:"15px",fontWeight:"bold"}}>Image</span><span className="settings" onClick={() => setIsSelected(!isSelected)}><FontAwesomeIcon icon={faGear} /></span></div>
            <span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
            </span>
            <div style={{display: isSelected ? 'block' : 'none'}} className='post_settings'>
                {postImg === ''
                ?<div>
                    <label htmlFor={node.id+'img_import'}>
                        <div className="img_import">
                            <p>Please, add image</p>
                        </div>
                    </label>
                    <input type="file" id={node.id+'img_import'} onChange={(e) => {
                        node.data.postImg = e.target.files[0]
                        node.data.size_not = e.target.files[0].size
                        savePostImg()
                        let [file] = e.target.files
                        setPostImg(URL.createObjectURL(file))
                    }} accept=".jpg, .jpeg, .png" hidden/>
                </div>
                :<div>
                    <div className="post_img_preview">
                        <img src={postImg} alt="Post img" />
                        <span title='Delete Post Image' className='delete_img' onClick={deletePostImg}><FontAwesomeIcon icon={faTrash} /></span>
                    </div>
                    <div className="post_description">
                        <textarea className='description nodrag' placeholder='Input Post Description' onChange={(e) => {
                            setPostDescription(e.target.value)
                        }}>{postDescription}</textarea>
                    </div>
                </div>
                }


            </div>
        </div>
    );
};

export default ImageNode;