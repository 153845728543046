import CommandNode from "./Basic/CommandNode";
import MessageNode from "./Basic/MessageNode";
import ButtonNode from "./Basic/ButtonNode";
import ImageNode from "./Basic/ImageNode";
import FileNode from "./Basic/FileNode";
import InlineNode from "./Basic/InlineNode";
import UrlNode from "./Basic/UrlNode";
import PostImportNode from "./Basic/PostImportNode";
import OpenAIChat from "./OpenAi/OpenAIChat";
import MessageByIdNode from "./Basic/MessageByIdNode";
import TextEvent from "./Basic/TextEvent";
import ApiNode from "./Basic/ApiNode";
import PollNode from "./Basic/PollNode";
import RssNode from "./ChannelAdmin/RssNode";
import ChannelSettingsNode from "./ChannelAdmin/ChannelSettingsNode";

const nodeTypes = {
    commandNode: CommandNode,
    textEvent: TextEvent,
    messageNode: MessageNode,
    messageByIdNode: MessageByIdNode,
    buttonNode: ButtonNode,
    inlineNode: InlineNode,
    urlNode: UrlNode,
    fileNode: FileNode,
    imageNode: ImageNode,
    postImportNode: PostImportNode,
    pollNode: PollNode,
    apiNode: ApiNode,
    openAIChat: OpenAIChat,
    rssNode: RssNode,
    channelSettingsNode: ChannelSettingsNode,
};

export default nodeTypes