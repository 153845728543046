import React, {useEffect, useState} from 'react';
import classes from "../../pages/Landing/styles.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProfileBotItem from "../Profile/ProfileBotList/ProfileBotItem";

const AdvantagesContainerLanding = (context) => {
    const [contItem, setConstItem] = useState(context.arr)
    return (
        <qcont>
            <cont>
                {contItem?.map((advantageItem, index) => (
                    <div className={classes.contItem}>
                        <div style={{display:'flex',justifyContent:'center', gap: 10, padding: '15px'}}>
                            <div className={classes.icon} style={{color:'var(--main-info)', backgroundColor:'var(--light-info)'}}><FontAwesomeIcon icon={advantageItem.icon} /></div>
                            <div style={{width:'80%'}}>
                                <h1>{advantageItem.title}</h1>
                                <p>{advantageItem.description}</p>
                            </div>
                        </div>
                        <div style={{padding: '0 10px'}}>
                            <plusesCont>
                                {advantageItem.pluses.map((advantageItemChild, index) => (
                                    <pluses>{advantageItemChild}</pluses>
                                ))}
                            </plusesCont>
                        </div>
                    </div>
                ))}
            </cont>
        </qcont>
    );
};

export default AdvantagesContainerLanding;