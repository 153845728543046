import {useState, useEffect, useRef} from 'react';
import React from 'react';
import classes from './main.module.css'

function FakeCodeGenerator() {
    const codeRef = useRef(null);
    const [isInView, setIsInView] = useState(false);
    const [fakeCode, setFakeCode] = useState(`import telebot
import random
import requests
from datetime import datetime, timedelta

# Set up the bot with your token
bot = telebot.TeleBot('YOUR_TOKEN')

# Define a message handler for the /start command
@bot.message_handler(commands=['start'])
def start_command(message):
    # Send a welcome message to the user
    bot.reply_to(message, 'Welcome to the Random Bot! Type /help for instructions.')

# Define a message handler for the /help command
@bot.message_handler(commands=['help'])
def help_command(message):
    # Send instructions to the user
    bot.reply_to(message, 'To generate a random number, just type /roll followed by the maximum number. For example, 
    /roll 100 will generate a number between 1 and 100. To get a random joke, type /joke. To get the weather, type /weather 
    followed by the city name. To get a random cat fact,')`);
    const codeText = `# Define a message handler for the /roll command
@bot.message_handler(commands=['roll'])
def roll_command(message):
    # Parse the maximum number from the message text
    try:
        max_number = int(message.text.split()[1])
    except:
        # If the maximum number is not provided or is not a valid integer, send an error message to the user
        bot.reply_to(message, 'Invalid input! Please provide a valid maximum number after the /roll command.')
        return
    
    # Generate a random number between 1 and the maximum number
    random_number = random.randint(1, max_number)    
    ### ###
  ##   #   ##
  #         #
   #       # 
     #    #
      ##
    # Send the random number to the user
    bot.reply_to(message, f'Your random number is {random_number}!')
    @bot.message_handler(commands=['roll'])
def roll_command(message):
    # Parse the maximum number from the message text
    try:
        max_number = int(message.text.split()[1])
    except:
        # If the maximum number is not provided or is not a valid integer, send an error message to the user
        bot.reply_to(message, 'Invalid input! Please provide a valid maximum number after the /roll command.')
        return
    
    # Generate a random number between 1 and the maximum number
    random_number = random.randint(1, max_number)
    
    # Send the random number to the user
    bot.reply_to(message, f'Your random number is {random_number}!')@bot.message_handler(commands=['roll'])
def roll_command(message):
    # Parse the maximum number from the message text
    try:
        max_number = int(message.text.split()[1])
    except:
        # If the maximum number is not provided or is not a valid integer, send an error message to the user
        bot.reply_to(message, 'Invalid input! Please provide a valid maximum number after the /roll command.')
        return
    
    # Generate a random number between 1 and the maximum number
    random_number = random.randint(1, max_number)
    
    # Send the random number to the user
    bot.reply_to(message, f'Your random number is {random_number}!')
    @bot.message_handler(commands=['roll'])
def roll_command(message):
    # Parse the maximum number from the message text
    try:
        max_number = int(message.text.split()[1])
        
    except:
        # If the maximum number is not provided or is not a valid integer, send an error message to the user
        bot.reply_to(message, 'Invalid input! Please provide a valid maximum number after the /roll command.')
        return
    
    # Generate a random number between 1 and the maximum number
    random_number = random.randint(1, max_number)
    
    # Send the random number to the user
    bot.reply_to(message, f'Your random number is {random_number}!')
`;

    useEffect(() => {
        if (isInView) {
            let i = 0;
            const timerId = setInterval(() => {
                if (i < codeText.length) {
                    if (codeText.charAt(i) === '\n') {
                        setFakeCode(fakeCode => fakeCode);
                    } else {
                        setFakeCode(fakeCode => fakeCode + codeText.charAt(i));
                    }
                    i++;
                } else {
                    clearInterval(timerId);
                }
            }, 0.5);
            return () => clearInterval(timerId);
        }
    }, [isInView]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsInView(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );
        if (codeRef.current) {
            observer.observe(codeRef.current);
        }
        return () => {
            if (codeRef.current) {
                observer.unobserve(codeRef.current);
            }
        };
    }, []);

    return (
        <pre className={classes.fakeCodeCont} ref={codeRef}>
            <p className={classes.fakeCodePre} dangerouslySetInnerHTML={{ __html: fakeCode }}></p>
        </pre>
    );
}

export default FakeCodeGenerator;
