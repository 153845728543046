import React, {useEffect, useState} from 'react';
import { Handle, Position } from 'reactflow';
import '../node.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {setMessage, setMessageType} from "../../../../reducers/generalSlice";
import {useDispatch} from "react-redux";

function CommandNode(node) {
    const dispatch = useDispatch()
    const [value, setValue] = useState(node.data.value)

    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }
    const IsValid = (connection) => {
        if(connection.targetHandle !== 'buttons'){
            return true
        }
        else{
            dispatch(setMessageType('warning'))
            dispatch(setMessage('This edge dont work with button node.'))
            return false
        }
    }
    useEffect(() => {
            node.data.value = value
    }, [value])
    return (
        <div className='node command_node'>
            <div>
                <span style={{fontSize:"15px",fontWeight:"bold"}}>Command</span>
            </div>
            {node.data.editAble === true
            ?<span className="command_field">
                <input className='nodrag' type="text" value={value} onChange={event => setValue(event.target.value)}/>
            </span>
            :<span className="icon">/{node.data.value}</span>
            }
            {node.data.deleteAble === true
            ?<span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
             </span>
            :<></>
            }
            <Handle
                type="source"
                position={Position.Bottom}
                isValidConnection={IsValid}
            />
        </div>
    );
}

export default CommandNode;