import {apiPath} from '../constants';
import axios from "axios";

export default class messagesApi {
    static async GetMessagesList() {
        return await axios.get(`${apiPath}/messages/list/`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async ReadAllMessages(data) {
        return await axios.post(`${apiPath}/messages/read/`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    static async DeleteAllMessages(data) {
        return await axios.put(`${apiPath}/messages/read/`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}