import React from 'react';
import classes from "./docs.module.css";
import {useSelector} from "react-redux";

const DocsMain = () => {
    const selectedDoc = useSelector(state => state.docs.selectedDoc)
    const docsCategories = useSelector(state => state.docs.docsCategories)
    const language = useSelector(state => state.general.language)

    return (
        <div className={classes.docsMain} >
            {docsCategories.map((docCat, index) =>
                docCat.docs_pages.map((DocState, index) => (
                    <div className={classes.innerDocsMain} dangerouslySetInnerHTML={{ __html: DocState.content[language] }} id={DocState.id}></div>
                ))
            )}
        </div>
    );
};

export default DocsMain;