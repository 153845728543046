import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../styles/Flow.css"
import {faFloppyDisk, faPlay, faRotateRight, faStop} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useFetching} from "../../hooks/useFetching";
import profileApi from "../../api/profileApi";
import classes from './ui.module.css'
import {setSelectedBot} from "../../reducers/profileSlice";
import {setMessage, setMessageType} from "../../reducers/generalSlice";
import {useTimeout} from "../../hooks/useTimeout";
import {useNavigate} from "react-router-dom";

const Ui = ({edges, nodes, reactFlowInstance}) => {
    const [isSystemctlBlocked, setIsSystemctlBlocked] = useState(false)
    const [serverSleep, isServerSleep, serverSleepError] = useTimeout(() =>{
        setIsSystemctlBlocked(false)
    }, 10000)
    const navigate = useNavigate()
    const selectedBot = useSelector((state) => state.profile.selectedBot)
    const dispatch = useDispatch()
    const [botSave, isBotSaveLoading, botSaveError] = useFetching(async () => {
        let localEdges = [];
        for (let edg of edges){
            if (nodes.filter(node => node.id === edg.source).length > 0 && nodes.filter(node => node.id === edg.target).length > 0){
                localEdges.push(edg)
            }
        }
        let flow = reactFlowInstance.toObject()
        console.log(JSON.stringify(flow))
        const response = await profileApi.BotSetup(selectedBot.id,{
            "edges": localEdges,
            "nodes": nodes,
            'interface': JSON.stringify(flow),
        })
        if(response.status === 200){
            dispatch(setMessage('Successfully saved bot||Your bot was successfully saved. To update changes you need to restart your bot'))
            dispatch(setMessageType('success'))
        }
    })
    const [runBot, isRunBotLoading, runBotError] = useFetching(async () => {
        if(!isSystemctlBlocked){
            const response = await profileApi.RunBot(selectedBot.id)
            if(response.status === 200){
                dispatch(setSelectedBot(response.data))
                setIsSystemctlBlocked(true)
                serverSleep()
            }else if(response.status === 202){
                navigate('/plan')
            }
        }
        else{
            dispatch(setMessage('Please, wait, your request is loading...'))
            dispatch(setMessageType('information'))
        }
    })
    const [restartBot, isRestartBotLoading, restartBotError] = useFetching(async () => {
        if(!isSystemctlBlocked){
            const response = await profileApi.RestartBot(selectedBot.id)
            dispatch(setSelectedBot(response.data))
            setIsSystemctlBlocked(true)
            serverSleep()
        }
        else{
            dispatch(setMessage('Please, wait, your request is loading...'))
            dispatch(setMessageType('information'))
        }
    })
    const [stopBot, isStopBotLoading, stopBotError] = useFetching(async () => {
        const response = await profileApi.StopBot(selectedBot.id)
        dispatch(setSelectedBot(response.data))
    })
    const handleError = () => {
        dispatch(setMessage('Warning, too many requests||Your systemctl is very busy right now, please try after few seconds'))
        dispatch(setMessageType('error'))
    }
    return (
        <div className={classes.UI}>
            <div onClick={botSave} className={classes.button} >
                {isBotSaveLoading
                    ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                    :<FontAwesomeIcon icon={faFloppyDisk}/>
                }
            </div>
            {selectedBot.is_author && (
              <>{selectedBot.status === 'stop'
                  ?<div onClick={isSystemctlBlocked ?handleError :runBot} className={isSystemctlBlocked ?classes.button+" "+classes.disableButton :classes.button} >
                      {isRestartBotLoading || isSystemctlBlocked
                          ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                          :<FontAwesomeIcon icon={faPlay}/>
                      }
                  </div>
                  :[<div onClick={isSystemctlBlocked ?handleError :restartBot} key={1} className={isSystemctlBlocked ?classes.button+" "+classes.disableButton :classes.button} >
                      {isRestartBotLoading || isSystemctlBlocked
                          ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                          :<FontAwesomeIcon icon={faRotateRight}/>
                      }
                  </div>,
                      <div onClick={stopBot} key={2} className={classes.button}>
                          {isStopBotLoading
                              ?<FontAwesomeIcon icon={faRotateRight} className='fa-spin'/>
                              :<FontAwesomeIcon icon={faStop}/>
                          }
                      </div>
                  ]}</>
            )}
        </div>
    );
};

export default Ui;