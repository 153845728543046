import React from 'react';
import {Link} from "react-router-dom";
import {apiPath} from "../../../constants";
import classes from "./styles.module.css";
import {useDispatch} from "react-redux";
import {setLanguage} from "../../../reducers/generalSlice";

const DocsHeader = () => {
    const dispatch = useDispatch()

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderBottom: '1px solid #8080804a',
        }}>
            <div style={{
                display: 'flex',
                padding:'15px 0',
                alignItems: 'center',
                fontSize: '20px',
                width: '95%',
                justifyContent: 'space-between',
            }}>
                <Link to={'/'}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}>
                        <img src={apiPath+"/static/images/ssqwdlogo2.svg"} alt="Buildo Logo" style={{
                            width: '35px',
                        }}/>
                        <h1 style={{
                            fontSize: '22px',
                            fontWeight: 'bold'
                        }}>Buildo</h1>
                    </div>
                </Link>
                <div>
                    <select name="language" style={{
                        fontSize: 20,
                        border: "none",
                        backgroundColor: "transparent"
                    }} onChange={(event) => {dispatch(setLanguage(event.target.value))}}>
                        <option value="us">🇬🇧 English</option>
                        <option value="uk">🇺🇦 Українська</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default DocsHeader;