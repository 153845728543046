import React from 'react';
import AdminBotListTopPanel from "../../components/Admin/AdminBotList/AdminBotListTopPanel";
import AdminUserList from "../../components/Admin/AdminUserList/AdminUserList";
import AdminUserListTopPanel from "../../components/Admin/AdminUserList/AdminUserListTopPanel";

const AdminPageUserList = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', borderRadius: '10px', boxShadow: '0px 0px 20px 0px #c9c9c933'}}>
            <AdminUserListTopPanel></AdminUserListTopPanel>
            <AdminUserList></AdminUserList>
        </div>
    );
};

export default AdminPageUserList;