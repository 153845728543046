import React, {useEffect, useState} from 'react';
import styles from './botdetails.module.css'
import SimpleLineChart from "../../General/Chart/SimpleLineChart";
import {useSelector} from "react-redux";
import PieChart from "../../General/Chart/PieChart";
import {monthNames, USER_STATIC_MAX_SIZE} from "../../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";

const DetailChartPanel = () => {
    const selectedBot = useSelector(state => state.profile.selectedBot)
    const [userStats, setUserStats] = useState(selectedBot.bot_stats.daily_users.users_stats)
    const [userActivity, setUserActivity] = useState(selectedBot.bot_stats.daily_activity)

    useEffect(() => {
        setUserStats(prevUserStats => {
            if (prevUserStats.length < 30) {
                const paddedArray = Array(30 - prevUserStats.length).fill(0);
                return [...paddedArray, ...prevUserStats];
            }
            return prevUserStats.slice(-30);
        });

        setUserActivity(prevUserActivity => {
            if (prevUserActivity.length < 30) {
                const paddedArray = Array(30 - prevUserActivity.length).fill(0);
                return [...paddedArray, ...prevUserActivity];
            }
            return prevUserActivity.slice(-30);
        });
    }, []);

    let lastMonthDate = []
    for (let i =0;i<30;i++){
        let date = new Date();
        date.setDate(date.getDate() - i);
        let formDate = date.getDate()+' '+monthNames[date.getMonth()].slice(0, 3).toLowerCase()
        lastMonthDate.push(formDate)
    }
    const [lineChartData, setLineChartData] = useState({
        datasets: []
    });

    useEffect(() => {
        setLineChartData({
            labels: lastMonthDate.reverse(),
            datasets: [
                {
                    label: "Users",
                    data: userStats,
                    backgroundColor: [
                        "#E2FBD7"
                    ],
                    borderColor: "#34B53A",
                    borderWidth: 4,
                    pointRadius: 1,
                    fill: true,

                },
                {
                    label: "Activity",
                    data: userActivity,
                    backgroundColor: [
                        "#DAD7FE"
                    ],
                    borderColor: "#4339F2",
                    borderWidth: 4,
                    pointRadius: 1,
                    fill: true,

                }
            ]
        })
    }, [userStats, userActivity])
    return (
        <div className={styles.ChartsCard}>
            {selectedBot.bot_stats.daily_users.users_stats.reduce((partialSum, a) => partialSum + a, 0) !== 0
            ?
            <div className={styles.LineChart}>
                <div style={{
                    height: '100%'
                }}>
                    <SimpleLineChart chartData={lineChartData} maxValue={selectedBot.highest_user}></SimpleLineChart>

                </div>
            </div>
            :<div className={styles.EmptyData}>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                }}>
                    <span style={{
                        fontSize: 50,
                    }}>
                        <FontAwesomeIcon icon={faClock} />
                    </span>
                    <div>
                        <p>Currently data isn't available</p><p>Please try tomorrow</p>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default DetailChartPanel;