import React from 'react';
import AdminBotUpdate from "../../components/Admin/AdminBotUpdate/AdminBotUpdate";

const AdminPageBotUpdate = () => {
    return (
        <div style={{
            padding: '10px',
            borderRadius: 10,
            boxShadow: '0px 0px 20px 0px #c9c9c933',
            backgroundColor: 'rgb(255 255 255 / 25%)',
        }}>
            <AdminBotUpdate></AdminBotUpdate>
        </div>
    );
};

export default AdminPageBotUpdate;