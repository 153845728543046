import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setMessage, setMessageType} from "../../../../reducers/generalSlice";
import {Handle, Position} from "reactflow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faXmark} from "@fortawesome/free-solid-svg-icons";

const UrlNode = (node) => {
    const dispatch = useDispatch()
    const [buttonLabel, setButtonLabel] = useState(String(node.data.label))
    const [buttonUrl, setButtonUrl] = useState(String(node.data.url))

    useEffect(() => {
        node.data.url = buttonUrl
        node.data.label = buttonLabel
    }, [buttonUrl,buttonLabel])
    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }
    const IsValid = (connection) => {
        if(connection.targetHandle === 'buttons'){
            return true
        }
        else{
            dispatch(setMessageType('warning'))
            dispatch(setMessage('This edge work only with button node.'))
            return false
        }
    }
    return (
        <div className='node url_node' style={{width: 250}}>
            <Handle type="target" id="buttons" position={Position.Top} isValidConnection={IsValid}/>
            <div><span style={{fontSize:"15px",fontWeight:"bold"}}>Url Button</span></div>
            <span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
            </span>
            <div style={{display: "flex", marginTop: 10, flexDirection:'column', gap: 10}}>
                <div>
                    <label htmlFor="button_label">Button Label</label>
                    <input type="text" id={'button_label'} className='button_name nodrag' placeholder="Button Label" onChange={e => setButtonLabel(e.target.value)} value={buttonLabel} />
                </div>
                <div>
                    <label htmlFor="button_url">Website Url</label>
                    <input type="text" id={'button_url'} className='button_name nodrag' placeholder="Website Url" onChange={e => setButtonUrl(e.target.value)} value={buttonUrl} />
                </div>
                {/*<span>*/}
                {/*    <input type="checkbox" name='isButtonKeyboard' id={'isButtonKeyboard'+node.id}/>Is Button Keyboard?*/}
                {/*</span>*/}
            </div>
        </div>
    );
};

export default UrlNode;