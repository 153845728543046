import React, {useEffect, useState} from 'react';


const ChannelSettingsNode = (node) => {
    const [channelUsername, setChannelUsername] = useState(String(node.data.channelUsername))
    const [adminUserId, setAdminUserId] = useState(String(node.data.adminUserId))

    useEffect(() => {
        node.data.channelUsername = channelUsername
        node.data.adminUserId = adminUserId
    }, [channelUsername, adminUserId])

    return (
        <div className='node channel_settings_node' style={{width: 200}}>
            <div><span style={{fontSize:"15px",fontWeight:"bold", display:'flex', gap: 5, alignItems: 'center'}}>Channel Settings</span></div>
            <div style={{display:"flex", marginTop: 10, flexDirection: 'column', gap: 5}}>
                <div style={{display: 'flex'}}>
                    <input type="text" className='input nodrag' placeholder="Channel @username"
                           onChange={e => setChannelUsername(e.target.value)} value={channelUsername} />
                </div>
                <div style={{display: 'flex'}}>
                    <input type="text" className='input nodrag' placeholder="Admin user id"
                           onChange={e => setAdminUserId(e.target.value)} value={adminUserId}/>
                </div>
            </div>
        </div>
    );
};

export default ChannelSettingsNode;