import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {Handle, Position} from "reactflow";
import {setMessage, setMessageType} from "../../../../reducers/generalSlice";
import {useDispatch} from "react-redux";

const TextEvent = (node) => {
    const dispatch = useDispatch()
    const deleteNode = () => {
        node.data.setNodes((nodes) => nodes.filter((nod) => nod.id !== node.id))
    }
    const IsValid = (connection) => {
        if(connection.targetHandle === 'openAIChat'){
            return true
        }
        else{
            dispatch(setMessageType('warning'))
            dispatch(setMessage('This edge dont work with button node.'))
            return false
        }
    }
    return (
        <div className='node command_node'>
            <div>
                <span style={{fontSize:"15px",fontWeight:"bold"}}>Text Event</span>
            </div>
            <span className="close_butt" onClick={deleteNode}>
                <FontAwesomeIcon icon={faXmark} />
             </span>
            <Handle
                type="source"
                position={Position.Bottom}
                isValidConnection={IsValid}
            />
        </div>
    );
};

export default TextEvent;