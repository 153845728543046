import React, {useEffect} from 'react';
import LandingHeader from "../../components/General/Header/LandingHeader";
import classes from "./styles.module.css";
import Oauth from "../../components/Auth/Oauth/oauth";
import SnackBar from "../../components/General/Snackbar/SnackBar";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../reducers/authSlice";
import VerifyEmail from "../../components/Auth/Oauth/VerifyEmail";

const LoginPage = () => {
    const token = useSelector((state) => state.auth.token)
    const user = useSelector(state => state.auth.user)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setUser({}))
    }, [])
    return (
        <div style={{
            display:'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh'
        }}>
            <LandingHeader></LandingHeader>
            <div className={classes.login_container}>
                {Object.keys(user).length === 0
                    ?<Oauth></Oauth>
                    :<VerifyEmail></VerifyEmail>
                }
            </div>
            <SnackBar></SnackBar>
        </div>
    );
};

export default LoginPage;