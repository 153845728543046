import React, {useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBorderAll, faListUl, faTrash} from "@fortawesome/free-solid-svg-icons";
import classes from './AdminBotList.module.css'
import {useDispatch, useSelector} from "react-redux";
import {
    setAdminBotListSearch,
    setAdminBotListSelect,
    setAdminBotListSort,
    setListTypeBlock
} from "../../../reducers/adminSlice";
import {useFetching} from "../../../hooks/useFetching";
import adminApi from "../../../api/adminApi";

const AdminBotListTopPanel = () => {
    const dispatch = useDispatch()
    const adminBotListSelect = useSelector(state => state.admin.adminBotListSelect)
    const [deleteBotAdmin, isDeleteBotAdminLoading, deleteBotAdminError] = useFetching(async () => {
        for (let botId of adminBotListSelect) {
            const response = await adminApi.DeleteBotAdmin(botId);
        }
        dispatch(setAdminBotListSelect(['deleted']));
    });

    return (
        <div style={{
            display: 'flex',
            padding: '15px 20px',
            backgroundColor: 'rgb(255 255 255 / 25%)',
            border: '1px solid rgba(187, 187, 187, 0.23)',
            borderBottom: 'none',
            borderTopLeftRadius: ' 10px',
            borderTopRightRadius: '10px'
        }}>
            <div className={classes.AdminBotListPanel}>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                }}>
                    Admin Bot List
                </div>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'end',
                    width: '80%'
                }}>
                    <div className={classes.SearchBox}>
                        <input type="text" placeholder={'Search...'} onChange={(e) => {
                            dispatch(setAdminBotListSearch(e.target.value))
                        }}/>
                    </div>
                    <div className={classes.PanelButton} onClick={deleteBotAdmin}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </div>
                    <div className={classes.AddNewBot}>
                        Add New Bot
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminBotListTopPanel;